export const situationsSeven = [
  {
    id: 1,
    text: "She has misunderstanding with her sister-in-law which often leads to fight and separation. She would...",
    response:
      "Initiate open communication and find a peaceful resolution with her sister-in-law.",
  },
  {
    id: 2,
    text: "His senior officer asked him to send him meals out of the trainees mess that he was looking after. He...",
    response:
      "Politely decline and explain the importance of maintaining discipline and following procedures.",
  },
  {
    id: 3,
    text: "His father was a successful businessman. Later he come to know that his father was a smuggler. He...",
    response: "Convince his father to surrender and Start a new life..",
  },
  {
    id: 4,
    text: "He was going around with his friends when some anti social elements confronted his group. He...",
    response:
      "Stay calm, assess the situation, and prioritize the safety of himself and his friends.",
  },
  {
    id: 5,
    text: "He being a CO is informed that one of the jawans of his unit tried to shoot himself, but he is fine now. He...",
    response:
      "Ensure immediate medical assistance and provide necessary support to the jawan and setup investigation.",
  },
  {
    id: 6,
    text: "His wife demands partition from his parents but he doesn’t want to leave them. He...",
    response:
      "Initiate a dialogue, understand each other's perspectives, and work towards a mutually agreeable solution.",
  },
  {
    id: 7,
    text: "He was made in-charge of a recruits mess. He heard complaints of the staff not providing sufficient food to them. He...",
    response:
      "Investigate the matter, address the staff's concerns, and ensure adequate provision of food to the recruits.",
  },
  {
    id: 8,
    text: "In an encounter between police and criminals. The criminals held two children hostage and you are in charge. You...",
    response:
      "Take calculated steps to ensure the safe release of the hostages.",
  },
  {
    id: 9,
    text: "He was in charge of an operation against the naxalites. He was going with his men when they were ambushed by the adversaries. He...",
    response:
      "Take immediate cover, assess the situation, and devise a strategic plan to counter the ambush.",
  },
  {
    id: 10,
    text: "He was appointed leader of a hiking trip of the college. He...",
    response:
      "Plan the itinerary, ensure safety measures are in place, and lead the group effectively during the hiking trip.",
  },
  {
    id: 11,
    text: "On her wedding ceremony the groom was demanding unconditional dowry and all the efforts to resolve have failed. She...",
    response:
      "Stand up against dowry demands, seek legal assistance if necessary, and prioritize her own well-being.",
  },
  {
    id: 12,
    text: "While going for an urgent meeting, he find road jammed. He...",
    response:
      "Assess alternate routes, communicate the delay to concerned parties, and find a way to reach the meeting on time.",
  },
  {
    id: 13,
    text: "He found his elder brother gambling in a bar at midnight. He...",
    response:
      "Confront his brother, express concern, and encourage him to seek help for his gambling addiction.",
  },
  {
    id: 14,
    text: " He caught a CSD member selling goods at market price. He...",
    response:
      "Report the incident to the appropriate authorities and provide necessary evidence for further investigation.",
  },
  {
    id: 15,
    text: "His senior officer called him to his office for some urgent work, but it is raining heavily. He...",
    response:
      "Make necessary arrangements to protect important documents from rain and promptly proceed to his senior officer's office.",
  },
  {
    id: 16,
    text: "His house caught fire and his youger sister is trapped inside. He...",
    response: `Went inside covered in a wet balnket and evacuated his sister safely.`,
  },
  {
    id: 17,
    text: "Your younger brother failed in undergradute final exams. You...",
    response:
      "Offer support, encourage him to reassess his study approach, and try again.",
  },
  {
    id: 18,
    text: "You found your neighbour slapping his wife. You...",
    response:
      "Intervene, ensure the immediate safety of the wife, and report the incident to the authorities if necessary.",
  },
  {
    id: 19,
    text: "His friend hit him over a political discussion. He...",
    response:
      "Maintain composure, engage in a calm dialogue, and seek understanding to resolve the disagreement peacefully.",
  },
  {
    id: 20,
    text: "There is a flood in his village and many houses have fallen down. He...",
    response:
      "Initiate immediate rescue and relief efforts, coordinate with local authorities, and provide support to affected individuals.",
  },
  {
    id: 21,
    text: "His step mother refuses to give him more money to see a movie. He...",
    response:
      "Respect her decision, find alternative ways to earn money, and prioritize financial responsibility.",
  },
  {
    id: 22,
    text: "While travelling in a train, he involved himself into a discussion. Nobody agreed with his arguments. He...",
    response:
      "Respect differing opinions, listen to others' viewpoints, and engage in a healthy debate without creating conflict.",
  },
  {
    id: 23,
    text: "He was fighting for President’s election in his college. All of a sudden he found that his own supporters were supporting his opponents. He...",
    response:
      "Adapt to the situation, focus on his campaign's positive aspects, and strategize to win over supporters.",
  },
  {
    id: 24,
    text: "He prepared well for the exam. But to his surprise he failed in it. He...",
    response:
      "Analyze his mistakes, learn from the experience, and develop a plan to improve his performance in future exams.",
  },
  {
    id: 25,
    text: "While crossing a jungle, he observed that a tiger was standing just 10 yards from him. He...",
    response:
      "Remain calm, slowly move away without alarming the tiger, and ensure personal safety.",
  },
  {
    id: 26,
    text: "While going through a jungle, he noticed that someone was following him with a rifle. He...",
    response:
      "Maintain awareness, change direction discreetly, and seek help or find a safe location to avoid any potential danger.",
  },
  {
    id: 27,
    text: "She wanted to be an engineer but her father wants her to send her in medical profession. She...",
    response:
      "Have an open and honest conversation with her father, express her aspirations, and convince him.",
  },
  {
    id: 28,
    text: "The students of his college wanted to give a call for a strike over unfair marks distribution. They asked for his support. He...",
    response: `Understand the grievances, and suggest taking matter to higher authority bofore going on strike.`,
  },
  {
    id: 29,
    text: "He received a letter that his exam were preponed by two months. He was not well prepared. He...",
    response:
      "Assess the available study time, prioritize subjects/topics, seek additional resources if needed, and intensify preparation efforts.",
  },
  {
    id: 30,
    text: "While going through a jungle suddenly one armed tribal had confronted him. He...",
    response:
      "Remain calm, show respect, and try to establish peaceful communication with the tribal to understand their intentions.",
  },
  {
    id: 31,
    text: "A girl from his class reported against him for abusing her. He...",
    response:
      "Cooperate with the investigation, present his side of the story, and respect due process.",
  },
  {
    id: 32,
    text: "On reaching late on parade, his Senior humiliated him and gave punishment. He...",
    response:
      "Accept the punishment gracefully, learn from the experience, and strive to be punctual.",
  },
  {
    id: 33,
    text: "He is about to go to his friend's party when his boss informed him about an urgent meeting. He...",
    response:
      "Prioritize work responsibilities, apologize to his friend, and reschedule the plans.",
  },
  {
    id: 34,
    text: "He was going on temp duty in military vehicle but suddenly he developed fever. He...",
    response:
      "Inform the concerned authorities, seek medical attention, and follow the recommended course of action.",
  },
  {
    id: 35,
    text: "In the train while she was about to sleep, she noticed that a thief was lifting the luggage of a co-passenger. She...",
    response:
      "Alert the co-passenger, nabbed him with other's help and handed him over to the RPF.",
  },
  {
    id: 36,
    text: "You are at the bank when some robbers enter and start firing. You...",
    response: "Take cover, stay calm, and try to contact police via Mobile.",
  },
  {
    id: 37,
    text: "On his return from a night show, his car is attacked by robbers. He...",
    response:
      "Stay inside the car, lock the doors and windows, and drive away safely at high speed.",
  },
  {
    id: 38,
    text: "His father has planned for his eye operation but during the period, he will be on a course. So he...",
    response:
      "Discuss the situation with his father, prioritize health needs and ask other family member to assist with his operation.",
  },
  {
    id: 39,
    text: "He has been asked to deliver a lecture on ‘Dowry System’ in his college. He does not have much knowledge on the subject. He...",
    response:
      "Conduct research, gather relevant information, and prepare thoroughly for the lecture.",
  },
  {
    id: 40,
    text: "Your sister is getting married very soon, but your wife also wants to open a beauty parlour, whereas you might run short of money for your sister's marriage. You...",
    response:
      "Have an open conversation with your wife, explore financial options, and find a mutually beneficial solution.",
  },
  {
    id: 41,
    text: "Your sister’s marriage and annual inspection are scheduled on the same day. You...",
    response:
      "Request a rescheduling of the annual inspection or find a suitable alternative arrangement.",
  },
  {
    id: 42,
    text: "He opened his packet of food, and a female beggar with a small baby wanted a share of food. He...",
    response:
      "Share some food with the beggar and consider providing additional help or support if possible.",
  },
  {
    id: 43,
    text: "She is M.Sc. in Maths. Her mother has been hospitalized, and her father has lost his job. She...",
    response:
      "Start a part time work somewhere and support her family financially and emotionally.",
  },
  {
    id: 44,
    text: "His father wanted him to quit military service, but he wanted to continue. He...",

    response:
      "Have a respectful conversation with his father, express his aspirations, and seek understanding.",
  },
  {
    id: 45,
    text: "After reaching his exam center, he found that he forgot to take writing material. He...",
    response:
      "Ask a fellow student for spare materials, inform the invigilator, and request assistance.",
  },
  {
    id: 46,
    text: "He trusted his married friend a lot but realized that he had developed an illicit relationship with his sister. He...",
    response:
      "Confront his friend, express disappointment, and reassess the friendship.",
  },
  {
    id: 47,
    text: "His father was a deputy collector. He observed his lavish lifestyle due to his indulgence in malpractices. He...",
    response:
      "Consider the ethical implications, have a candid conversation with his father, and encourage him to change.",
  },
  {
    id: 48,
    text: "He checked a person and stopped him from troubling a woman. Later he saw that very person being hit by a car. He was bleeding. He...",
    response:
      "Render immediate assistance, call emergency services, and provide first aid if capable.",
  },
  {
    id: 49,
    text: "He was appearing for a school test. He saw the invigilator, who was one of his own teachers, helping a boy. He...",
    response:
      "Focus on his own test, and later inform the matter to higher authority.",
  },
  {
    id: 50,
    text: "In a debate when she started speaking, every spectator started laughing at her. She...",
    response:
      "Stay confident, address the situation with composure, and continue expressing her thoughts.",
  },
  {
    id: 51,
    text: "He feels disguised as people with whom he deals are not co‐operative but self‐centered. He...",
    response:
      "Assess the situation objectively, adapt communication strategies, and maintain professionalism.",
  },
  {
    id: 52,
    text: "He wants to do something new. What will be his next step...",
    response:
      "Identify interests and skills, research new opportunities, and create an action plan.",
  },
  {
    id: 53,
    text: "His household servant who looked after his younger brother often ignored his instructions. He...",
    response:
      "Address the issue directly with the servant, communicate expectations clearly, and establish boundaries.",
  },
  {
    id: 54,
    text: "While on night duty on a post near LOC, he hears some sound of movement. He...",
    response:
      "Remain vigilant, assess the situation cautiously, and report any suspicious activities.",
  },
  {
    id: 55,
    text: "You are walking down the road one fine afternoon, and a man steals a lady's handbag and in the process, stabs the lady. You...",
    response:
      "Ensure the lady's safety, call emergency services, and provide immediate assistance.",
  },
  {
    id: 56,
    text: "He is superseded for promotion, although he feels he is fit for promotion in all respects. He...",
    response:
      "Evaluate the reasons for supersession, seek feedback, and develop a plan for professional growth.",
  },
  {
    id: 57,
    text: "While going for an urgent work on a scooter, you were caught by the traffic police as you had no license. He...",
    response:
      "Cooperate with the traffic police, accept the consequences, and take steps to obtain a license.",
  },
  {
    id: 58,
    text: "His team was to play a football match with another college. One day before the match, two good players of his team fell ill. He...",
    response:
      "Reorganize the team lineup, encourage and support remaining players, and strategize accordingly.",
  },
  {
    id: 59,
    text: "While walking on the bank of a canal, you noticed a lady jumping in the canal. You...",
    response:
      "Immediately call for help, try to reach out to the lady, and ensure her safety.",
  },
  {
    id: 60,
    text: "While undertaking a journey, she lost everything she was carrying. She would...",
    response:
      "Stay calm, report the incident to the authorities, and seek assistance to recover lost belongings.",
  },
];

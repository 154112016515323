export const situationsNine = [
  {
    id: 1,
    text: "You got a marriage proposal from a sexually harassed girl who is struggling for justice. You...",
    response:
      "Support her, empathize with her situation, and encourage her pursuit of justice.",
  },
  {
    id: 2,
    text: "You advised your friend to study regularly, but before the day of the exam, he is coming to you with lots of doubts, and you are on your serious revision. You...",
    response: "Allocate a specific time for addressing his doubts .",
  },
  {
    id: 3,
    text: "He was a school teacher in a village where cholera had spread. The authorities wanted to vacate the villagers, but they were reluctant. He...",
    response:
      "Educate the villagers about the risks, facilitate medical assistance, and convince them to evacuate.",
  },
  {
    id: 4,
    text: "He is at SSB, and the next day is his conference. Suddenly he got a phone call about his grandmother's demise. He...",
    response:
      "Attended the conference, informed authority about the situation and then left for home ASAP by the very next flight.",
  },
  {
    id: 5,
    text: "Your friend is accused of murdering his uncle, and you were the only eyewitness. You...",
    response:
      "Provide truthful testimony, cooperate with the investigation, and support due process.",
  },
  {
    id: 6,
    text: "In the train, two dacoits are looting everybody. They demanded his belongings also. He will...",
    response:
      "Remain calm, comply with their demands, and Later report the incident to the police.",
  },
  {
    id: 7,
    text: "He, as in charge of the picnic, has gone halfway with the picnic party and finds that food packets and music system had been left behind. He...",
    response:
      "Packed food from fast food vendors and continued with the journey.",
  },
  {
    id: 8,
    text: "He has gone to attend the marriage of his friend, and a scuffle took place between his friends and Baratis. The matter is likely to be reported to the police. He...",
    response:
      "Intervene to defuse the situation, counsel both parties, and prevent escalation.",
  },
  {
    id: 9,
    text: "In an exhibition, a fake company is attracting the crowd by false propaganda. He knows the reality. He...",
    response:
      "Expose the truth, report the deceptive practices to authorities, and protect consumer interests.",
  },
  {
    id: 10,
    text: "After two years of her marriage, she was shocked to know that her husband has developed illicit relations with another woman who is his colleague. She...",
    response:
      "Address the issue through open communication, seek professional counseling if necessary.",
  },
  {
    id: 11,
    text: "His wife wanted to continue her further studies. He...",
    response:
      "Support and encourage her aspirations, discuss practicalities, and explore options together.",
  },
  {
    id: 12,
    text: "His wife had failed in the University exams and blamed the marriage for her failure. He...",
    response:
      "Provide emotional support, encourage her to persevere, and explore possible solutions together.",
  },
  {
    id: 13,
    text: "He has been granted admission to one of the league colleges in the USA, but his mother doesn't want him to go out of the country. He...",
    response:
      "Have a conversation with his mother, discuss the benefits and opportunities, address concerns and convince her.",
  },
  {
    id: 14,
    text: "His father and uncle are not on good terms. His uncle has no child, and he treats him like his own child. His father wants to finish all relations with his uncle. He...",
    response:
      "Mediate the situation, encourage reconciliation, and promote family harmony.",
  },
  {
    id: 15,
    text: "In the N.C.C. camp, her commanding officer deployed her for the night patrolling along with three boys. She would...",
    response:
      "Follow orders, coordinate with the team, and execute the patrolling duties diligently.",
  },
  {
    id: 16,
    text: "During practice, a day before the match, the center forward twists his ankle and is not in the condition to play. As the captain of the team, he...",
    response:
      "Assess the available options, consult the coach, and make a strategic decision considering team dynamics.",
  },
  {
    id: 17,
    text: "He has some other plans in life, but his parents are forcing him to join the Indian Defense Services. He...",
    response:
      "Express his aspirations and interests to his parents, negotiate and seek mutual understanding.",
  },
  {
    id: 18,
    text: "You are asking a doubt to your professor in class, and he discourages you in front of other students. You...",
    response:
      "Approach the professor privately, seek clarification, or consult another reliable source.",
  },
  {
    id: 19,
    text: "All of a sudden, when he woke up at night, he found that due to a short circuit, sparks were coming out from the ceiling fan. He...",
    response:
      "Immediately switch off the power, ensure safety, and report the electrical issue.",
  },
  {
    id: 20,
    text: "His brother was becoming more and more short-tempered and quarrelsome after their father's death. He...",
    response:
      "Offer support and understanding, encourage open communication, and suggest seeking professional help if necessary.",
  },
  {
    id: 21,
    text: "His college boys challenged him because he reported against them as they were trying to beat his friend. He...",
    response:
      "Stand firm on principles, maintain integrity, and seek assistance from relevant authorities.",
  },
  {
    id: 22,
    text: "He was posted on a warship as a telegrapher, but suddenly the communication system failed. He...",
    response:
      "Troubleshoot the issue, attempt repairs if possible, and inform superior officers.",
  },
  {
    id: 23,
    text: "Some people were trying to harass his friend's sister, and he had tried to prevent them but to no avail. He...",
    response:
      "Take appropriate action, ensure the safety of the sister, and report the incident to authorities.",
  },
  {
    id: 24,
    text: "You are organizing a cricket match, and it started raining heavily the night before the match. You...",
    response:
      "Assess the weather conditions, consider rescheduling or making alternate arrangements.",
  },
  {
    id: 25,
    text: "During the time of National Emergency, he was asked to deliver a secret message. He...",
    response:
      "Handle the message with utmost secrecy, deliver it to the designated recipient discreetly.",
  },
  {
    id: 26,
    text: "In a monthly meeting of the colony, he is asked to address the meeting extempore on various problems facing the colony. He...",
    response:
      "Prepare key points, speak confidently, and propose constructive solutions.",
  },
  {
    id: 27,
    text: "In his college, he is offered the role of the hero in a play which is later changed to the role of the villain. He...",
    response:
      "Adapt to the new role, approach it with enthusiasm, and deliver a memorable performance.",
  },
  {
    id: 28,
    text: "She would like to marry a boy who is from a different religion. Parents from both sides are quarreling, and there is an apprehension of communal riot. She...",
    response:
      "Encourage understanding and tolerance, facilitate open dialogue, and explore possibilities for peaceful resolution.",
  },
  {
    id: 29,
    text: "He appeared before the Service Selection Board but failed to be selected. He...",
    response:
      "Accept the outcome gracefully, seek feedback for improvement, and started preparing for next attempt.",
  },
  {
    id: 30,
    text: "He loses his brother-in-law in an accident when he was with him on a motorbike. He...",
    response:
      "Provide emotional support to the family, assist with necessary arrangements, and cope with the loss together.",
  },
  {
    id: 31,
    text: "He always wanted to be a good football player, but he is not performing well. His brother is the captain of his college football team. He...",
    response:
      "Seek guidance and support from his brother to improve his performance.",
  },
  {
    id: 32,
    text: "He wanted to start a venture on his own. He needed some money to start the same. His father refused to help. He...",
    response:
      "Explore alternative funding sources or consider seeking investors for his venture.",
  },
  {
    id: 33,
    text: "He had made all preparations to join a group to go on a trip. But his father fell ill. He...",
    response:
      "Postpone the trip and prioritize taking care of his father's health.",
  },
  {
    id: 34,
    text: "While practicing for a match in a tournament, he broke his favourite racquet. He...",
    response:
      "Arrange for a replacement racquet or borrow one from a teammate.",
  },
  {
    id: 35,
    text: "Her friend is being forced to marry a boy of her own community whereas she had already chosen a boy who is from the different community. She...",
    response:
      "Support her friend's choice and help her communicate her preferences to her family.",
  },
  {
    id: 36,
    text: "He was one of the good players of his college Hockey team but was not selected as the captain of the team. He...",
    response:
      "Accept the decision gracefully and continue to contribute positively to the team.",
  },
  {
    id: 37,
    text: "While trying to reach the picnic spot ahead of others he lost his way. He...",
    response:
      "Ask for directions from locals or use navigation tools to find the way.",
  },
  {
    id: 38,
    text: "During Army attachment camp, his batch of volunteers declared a strike because of rude behavior of JCO, so he...",
    response:
      "Encourage peaceful dialogue, address concerns through proper channels, and maintain discipline.",
  },
  {
    id: 39,
    text: "The discussion had reached a tense point and he...",
    response:
      "Attempt to defuse the tension, encourage respectful communication, and find common ground.",
  },
  {
    id: 40,
    text: "He was out on a picnic with his college friends when one of the boys stole some mangoes from the garden and the gardener came out with his stick. He...",
    response:
      "Intervene, apologize to the gardener, and discourage any further wrongdoing.",
  },
  {
    id: 41,
    text: "He was in B.A. when his father lost his job. There was no other member in the family who could run the family expenses. He...",
    response:
      "Explore part-time job opportunities or seek financial assistance to support the family.",
  },
  {
    id: 42,
    text: "His father is being transferred to another city, but he doesn't want to leave the current city due to his school and friends. He...",
    response:
      "Express his concerns to his parents, and convince them for hostel.",
  },
  {
    id: 43,
    text: "He sees his enemy’s son drowning in the river. He...",
    response:
      "Put personal differences aside, act immediately to save the child's life.",
  },
  {
    id: 44,
    text: "His teacher is unable to solve a mathematical sum in the class. He...",
    response: "Respectfully offer assistance.",
  },
  {
    id: 45,
    text: "While on the way to her college by scooty, she heard the scream of a woman who was crying to save her son from burning as her house is on fire and there is no one around. She...",
    response:
      "Stop and help the woman and her son, call emergency services if necessary.",
  },
  {
    id: 46,
    text: "He is in a hurry to reach the office and found his motorcycle punctured. He...",
    response:
      "Look for alternative transportation options or seek help to repair the puncture quickly.",
  },
  {
    id: 47,
    text: "He was the captain of the basketball team, and his team was about to lose in the final. He...",
    response:
      "Motivate and rally the team, strategize to make a comeback and give their best effort.",
  },
  {
    id: 48,
    text: "He went to college for his exam and found his name missing in the list. He...",
    response:
      "Contact the college administration to clarify the situation and resolve the issue.",
  },
  {
    id: 49,
    text: "His friend asks him for some money, but he already knows that he has the habit of drinking. He...",
    response:
      "Express concerns about the friend's habit, offer non-monetary support or suggest alternative solutions.",
  },
  {
    id: 50,
    text: "On arrival at SSB, he found the candidates' dorm is not clean. He...",
    response:
      "Take the initiative to clean up the dorm and encourage others to maintain cleanliness.",
  },
  {
    id: 51,
    text: "His friend points out his shortcomings. He...",
    response:
      "Listen to the feedback, reflect on it, and strive for self-improvement.",
  },
  {
    id: 52,
    text: "He wants to do the following in his spare time...",
    response:
      "Identify hobbies or activities aligned with his interests and pursue them.",
  },
  {
    id: 53,
    text: "While travelling in a bus, few goondas were teasing her friend continuously. She would...",
    response:
      "Speak up against the harassment, seek help from fellow passengers or authorities.",
  },
  {
    id: 54,
    text: "In an examination hall, he reaches five minutes late. He...",
    response:
      "Enter quietly, settle down quickly, and focus on the remaining exam time.",
  },
  {
    id: 55,
    text: "He finds an old lady falling due to a jerk in a train compartment. He...",
    response: "Offer immediate assistance, help the lady to a safe position.",
  },
  {
    id: 56,
    text: "While participating in the group discussion, he found that some members are not co-operating. He...",
    response:
      "Encourage participation, mediate conflicts, and foster a cooperative atmosphere.",
  },
  {
    id: 57,
    text: "He found a wallet with money lying on the road. He...",
    response:
      "Try to identify the owner, if not possible, hand over the wallet to the authorities.",
  },
  {
    id: 58,
    text: "He is not sure of his success in the examination. He...",
    response:
      "Stay focused, study diligently, and maintain a positive mindset.",
  },
  {
    id: 59,
    text: "His parents were quarreling about his career options. He...",
    response:
      "Communicate his aspirations and concerns to his parents, seek their understanding.",
  },
  {
    id: 60,
    text: "He was without a job. His parents persuaded him to get married. His wife wanted to start a family soon. He...",
    response:
      "Consider job opportunities that align with his skills and aspirations before making any decisions about starting a family.",
  },
];

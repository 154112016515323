export const wordSix = [
  {
    id: 1,
    text: "Faith",
    response: "Faith is the foundation of hope.",
  },
  {
    id: 2,
    text: "Examination",
    response: "Exams are crucial for assessments.",
  },
  {
    id: 3,
    text: "Hero",
    response: "A hero is someone who selflessly helps others.",
  },
  {
    id: 4,
    text: "Inferior",
    response: "Inferiority is just a mindset, not a reality.",
  },
  {
    id: 5,
    text: "Irresponsible",
    response: "Neglecting duties and obligations is irresponsible.",
  },
  {
    id: 6,
    text: "Prosperity",
    response:
      "Achieving financial prosperity requires hard work and dedication.",
  },
  {
    id: 7,
    text: "Newspaper",
    response: "Newspaper provides daily updates on current events.",
  },
  {
    id: 8,
    text: "Accommodate",
    response: "Hostel provides accommodation for travelers.",
  },
  {
    id: 9,
    text: "Bluff",
    response: "Bluffing can ruin trust and relationships.",
  },
  {
    id: 10,
    text: "Conquest",
    response: "Conquest of power often leads to corruption.",
  },
  {
    id: 11,
    text: "Project",
    response: "Successful projects require discipline and perseverance.",
  },
  {
    id: 12,
    text: "Garden",
    response: "Nurturing a garden teaches patience and responsibility.",
  },
  {
    id: 13,
    text: "Dance",
    response: "Dance brings joy and unity.",
  },
  {
    id: 14,
    text: "Canteen",
    response: "Canteen promotes a sense of community.",
  },
  {
    id: 15,
    text: "Idea",
    response: "Ideas shape the world around us.",
  },
  {
    id: 16,
    text: "Bully",
    response: "Bullying is never acceptable behavior.",
  },
  {
    id: 17,
    text: "Keen",
    response: "Keen observation leads to wise decisions.",
  },
  {
    id: 18,
    text: "Harmful",
    response: "The use of harmful chemicals can lead to dire consequences.",
  },
  {
    id: 19,
    text: "Crowd",
    response: "Crowds often reflect society's values.",
  },
  {
    id: 20,
    text: "Cure",
    response: "The cure for ignorance is education.",
  },
  {
    id: 21,
    text: "Ignorance",
    response: "Ignorance leads to harm and regret.",
  },
  {
    id: 22,
    text: "Irritate",
    response: "An irritable person often lacks self-control.",
  },
  {
    id: 23,
    text: "Officer",
    response: "Officers serve and protect with honor and integrity.",
  },
  {
    id: 24,
    text: "Science",
    response: "Science shapes our understanding of the world.",
  },
  {
    id: 25,
    text: "Heinous",
    response: "Heinous crimes deserve severe punishment.",
  },
  {
    id: 26,
    text: "Fish",
    response: "Fish can teach us patience and persistence.",
  },
  {
    id: 27,
    text: "Jail",
    response: "Incarceration teaches valuable lessons about justice.",
  },
  {
    id: 28,
    text: "Cricket",
    response: "Cricket teaches sportsmanship, teamwork, and respect.",
  },
  {
    id: 29,
    text: "Push",
    response: "Pushing limits fosters growth and resilience.",
  },
  {
    id: 30,
    text: "Command",
    response: "Obedience to rightful authority brings peace and order.",
  },
  {
    id: 31,
    text: "Mindful",
    response: "Mindfulness promotes inner peace and clarity.",
  },
  {
    id: 32,
    text: "Pencil",
    response: "A pencil teaches us to constantly sharpen our minds.",
  },
  {
    id: 33,
    text: "Boredom",
    response: "Boredom leads to unproductive thoughts and actions.",
  },
  {
    id: 34,
    text: "Fix",
    response: "Fixing mistakes demonstrates responsibility and integrity.",
  },
  {
    id: 35,
    text: "Hot",
    response: "Excessive heat can be dangerous.",
  },
  {
    id: 36,
    text: "Aspiration",
    response: "Aspiration drives one towards greater heights.",
  },
  {
    id: 37,
    text: "Save",
    response: "Saving money for a rainy day teaches responsibility.",
  },
  {
    id: 38,
    text: "Patriotism",
    response: "Honoring one's country fosters true patriotism.",
  },
  {
    id: 39,
    text: "Herd",
    response: "Following the herd can lead to misguided actions.",
  },
  {
    id: 40,
    text: "Childhood",
    response: "Remembering childhood moments brings joy and reflection.",
  },
  {
    id: 41,
    text: "Lie",
    response: "Lying ultimately destroys trust and integrity.",
  },
  {
    id: 42,
    text: "Diagnose",
    response: "Correctly diagnosing illness can save lives.",
  },
  {
    id: 43,
    text: "Excel",
    response: "Excel in kindness, compassion, and humility.",
  },
  {
    id: 44,
    text: "Control",
    response: "Self-control leads to self-respect and discipline.",
  },
  {
    id: 45,
    text: "Code",
    response: "Honor the code, for it is the foundation of integrity.",
  },
  {
    id: 46,
    text: "Parade",
    response: "Parade showcases community unity and pride.",
  },
  {
    id: 47,
    text: "Fantasy",
    response: "Fantasy fuels creativity, but reality shapes character.",
  },
  {
    id: 48,
    text: "Lover",
    response: "Lover of others shows true kindness and selflessness.",
  },
  {
    id: 49,
    text: "World",
    response: "The world is a beautiful place, but also a cruel one.",
  },
  {
    id: 50,
    text: "Gift",
    response: "Presenting a heartfelt gift shows genuine appreciation.",
  },
  {
    id: 51,
    text: "Court",
    response: "Justice prevails in the court of law.",
  },
  {
    id: 52,
    text: "Japan",
    response: "Japan serves as a model of diligence and respect.",
  },
  {
    id: 53,
    text: "New",
    response: "Embracing new experiences leads to personal growth.",
  },
  {
    id: 54,
    text: "Red",
    response: "Red represents danger, caution, and boldness.",
  },
  {
    id: 55,
    text: "Jovial",
    response: "Jovial nature brings joy and positivity.",
  },
  {
    id: 56,
    text: "Modest",
    response: "Modest behavior earns respect and admiration.",
  },
  {
    id: 57,
    text: "Curse",
    response: "Cursing others brings negativity into our own lives.",
  },
  {
    id: 58,
    text: "Radio",
    response: "Radio waves connect people across distances.",
  },
  {
    id: 59,
    text: "Sing",
    response: "Sing to spread joy and love.",
  },
  {
    id: 60,
    text: "Language",
    response: "There are 22 Modern Indian Languages.",
  },
  {
    id: 60,
    text: "Language",
    response: "There are 22 Modern Indian Languages.",
  },
];

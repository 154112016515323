import React, { useState } from "react";
import { lecturette } from "./content";
import { Link } from "react-router-dom";
import Navbar from "../../navbar/navbar";
import * as ImIcons from "react-icons/im";
import Footers from "../../footer/Footers";
import "./lecturette.css";
import { useSelector } from "react-redux";

const Lecturette = () => {
  const auth = useSelector((state) => state.auth);
  const isPremium = auth?.user?.payment?.isPaid;

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
  };

  let filteredLecturette = lecturette.filter((item) => {
    if (selectedCategory === "All") {
      return true;
    }
    return item.type === selectedCategory;
  });

  filteredLecturette = filteredLecturette.filter((item) => {
    const title = item.title.toLowerCase();
    const query = searchQuery.toLowerCase();
    return title.includes(query);
  });

  return (
    <>
      <Navbar />
      <div className="news">
        <div className="n-header">
          <h1>Lecturette</h1>
          <div className="lec-search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
            />
            <br />
            <select value={selectedCategory} onChange={handleCategorySelect}>
              <option value="All">All</option>
              <option value="Easy">Easy</option>
              <option value="Medium">Medium</option>
              <option value="Hard">Hard</option>
            </select>
          </div>
        </div>

        <div className="n-box">
          {filteredLecturette.map((item, index) => {
            return (
              <div key={index}>
                <Link
                  to={
                    isPremium || item.id === 1 || item.id === 2 || item.id === 3
                      ? `/lecturette/${item.title.replace(/\s/g, "-")}`
                      : "/premium"
                  }
                >
                  <div className="n-img">
                    <img src={item.img} alt={item.alt} />
                  </div>
                  <div
                    className="lock-icon"
                    style={{
                      color: `${
                        isPremium ||
                        item.id === 1 ||
                        item.id === 2 ||
                        item.id === 3
                          ? "green"
                          : "red"
                      }`,
                    }}
                  >
                    {isPremium ||
                    item.id === 1 ||
                    item.id === 2 ||
                    item.id === 3 ? (
                      <ImIcons.ImUnlocked />
                    ) : (
                      <ImIcons.ImLock />
                    )}
                  </div>
                  <div className="n-content">
                    <p className="n-title">{item.title}</p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        {/* <h1 style={{ color: "red" }}>
          Note:- Everyday 10 New Lecturette Topics Will be Added.
        </h1> */}
      </div>
      <Footers />
    </>
  );
};

export default Lecturette;

export const wordSeven = [
  {
    id: 1,
    text: "Fit",
    response: "Fit bodies foster strong minds",
  },
  {
    id: 2,
    text: "Celebrate",
    response: "Embrace joy and share with others.",
  },
  {
    id: 3,
    text: "Policy",
    response: "Honesty and fairness guide every policy decision.",
  },
  {
    id: 4,
    text: "Pay",
    response: "Honesty in paying debts cultivates integrity and trust.",
  },
  {
    id: 5,
    text: "Preach",
    response: "Practice what you profess to believe.",
  },
  {
    id: 6,
    text: "Organisation",
    response: "Order and harmony foster success in organization.",
  },
  {
    id: 7,
    text: "Utilize",
    response: "Maximizing resources through utilization teaches efficiency.",
  },
  {
    id: 8,
    text: "Carefully",
    response:
      "Choose your words and actions Carefully for they shape your integrity",
  },
  {
    id: 9,
    text: "Atom",
    response: "Atom's tiny structure holds immense power within.",
  },
  {
    id: 10,
    text: "Junior",
    response: "",
  },
  {
    id: 11,
    text: "Naked",
    response: "Naked truth exposes true character.",
  },
  {
    id: 12,
    text: "Ask",
    response: "Asking shows humility and opens doors to understanding.",
  },
  {
    id: 13,
    text: "Bachelor",
    response:
      "A Bachelor's path to success often requires perseverance and self-discipline.",
  },
  {
    id: 14,
    text: "Barrier",
    response: "Breaking down barriers leads to understanding and growth.",
  },
  {
    id: 15,
    text: "Fellow",
    response: "A true fellow strives to uplift others, not tear them down.",
  },
  {
    id: 16,
    text: "Haste",
    response: "Haste makes waste, patience reaps success.",
  },
  {
    id: 17,
    text: "Freedom",
    response: "Sacrifice and unity pave the path to true freedom.",
  },
  {
    id: 18,
    text: "Illiterate",
    response:
      "Ignorance may be bliss, but illiteracy is a curse, robbing individuals of their potential.",
  },
  {
    id: 19,
    text: "Must",
    response:
      "Honor and integrity must be upheld, regardless of the consequences.",
  },
  {
    id: 20,
    text: "Flexible",
    response: "Adapting to change demonstrates strength and integrity.",
  },
  {
    id: 21,
    text: "Oath",
    response: "",
  },
  {
    id: 22,
    text: "Purity",
    response: "",
  },
  {
    id: 23,
    text: "Upset",
    response: "",
  },
  {
    id: 24,
    text: "Virtue",
    response: "",
  },
  {
    id: 25,
    text: "Please",
    response: "",
  },
  {
    id: 26,
    text: "Tutor",
    response: "",
  },
  {
    id: 27,
    text: "Expand",
    response: "",
  },
  {
    id: 28,
    text: "Bycott",
    response: "",
  },
  {
    id: 29,
    text: "Hair",
    response: "",
  },
  {
    id: 30,
    text: "Backup",
    response: "",
  },
  {
    id: 31,
    text: "Abandon",
    response: "",
  },
  {
    id: 32,
    text: "Yell",
    response: "",
  },
  {
    id: 33,
    text: "Zoo",
    response: "",
  },
  {
    id: 34,
    text: "Fix",
    response: "",
  },
  {
    id: 35,
    text: "Cancer",
    response: "",
  },
  {
    id: 36,
    text: "Garbage",
    response: "",
  },
  {
    id: 37,
    text: "Geography",
    response: "",
  },
  {
    id: 38,
    text: "Zero",
    response: "",
  },
  {
    id: 39,
    text: "Yesterday",
    response: "",
  },
  {
    id: 40,
    text: "Fact",
    response: "",
  },
  {
    id: 41,
    text: "E-mail",
    response: "",
  },
  {
    id: 42,
    text: "Damage",
    response: "",
  },
  {
    id: 43,
    text: "Deaf",
    response: "",
  },
  {
    id: 44,
    text: "Bodyguard",
    response: "",
  },
  {
    id: 45,
    text: "Yard",
    response: "",
  },
  {
    id: 46,
    text: "Fake",
    response: "",
  },
  {
    id: 47,
    text: "Hammer",
    response: "",
  },
  {
    id: 48,
    text: "Handsome",
    response: "",
  },
  {
    id: 49,
    text: "Accuse",
    response: "",
  },
  {
    id: 50,
    text: "Baby",
    response: "",
  },
  {
    id: 51,
    text: "Dare",
    response: "",
  },
  {
    id: 52,
    text: "England",
    response: "",
  },
  {
    id: 53,
    text: "Earth",
    response: "",
  },
  {
    id: 54,
    text: "Geothermal",
    response: "",
  },
  {
    id: 55,
    text: "Actress",
    response: "",
  },
  {
    id: 56,
    text: "Farm",
    response: "",
  },
  {
    id: 57,
    text: "Barometer",
    response: "",
  },
  {
    id: 58,
    text: "Grandmother",
    response: "",
  },
  {
    id: 59,
    text: "Gender",
    response: "",
  },
  {
    id: 60,
    text: "Caution",
    response: "",
  },
  {
    id: 60,
    text: "Caution",
    response: "",
  },
];

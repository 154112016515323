import { questionsOne } from "./questionsP1";
import { questionsTwo } from "./questionsP2";
import { questionsThree } from "./questionsP3";
import { questionsFour } from "./questionsP4";
import { questionsFive } from "./questionsP5";

export const questions = {
  one: questionsOne,
  two: questionsTwo,
  three: questionsThree,
  four: questionsFour,
  five: questionsFive,
};

export const situationsTen = [
  {
    id: 1,
    text: " On entering into the compartment he found all the seats occupied by one family and their luggage. When he requested them to put the stuff on the floor they started abusing him. He...",
    response: "Talk to the train staff and get the matter resolved.",
  },
  {
    id: 2,
    text: "You as a player of football team required to play the match. Suddenly, on the day of the match, you have a high fever. But your team captain does not allow you to leave the game. He...",
    response: "Inform the captain about your condition and seek a replacement.",
  },
  {
    id: 3,
    text: "You are the only one from your department to get selected for the college cricket team, and while at practice, your senior scolds you badly for mistakes. You will...",
    response:
      "Learn from the feedback, improve your performance, and prove yourself.",
  },
  {
    id: 4,
    text: "You asked a person to do plumbing work in your apartment, and suddenly the man loses his balance and falls from the first floor. You will...",
    response:
      "Immediately call for medical help and provide first aid if possible.",
  },
  {
    id: 5,
    text: "On the way to give money for your friend's father who is at the hospital for surgery, you found your bag with money is missing. You will...",
    response:
      "Report the incident to the authorities and find alternative means to support your friend.",
  },
  {
    id: 6,
    text: "You own a business and have a good amount of money. Your brother scored fewer marks in the board exam while your brother's friend scored good marks and doesn't have enough money to pursue engineering. Your brother also wants to pursue the same. You...",
    response:
      "Finance Brother's and his friend's study and encourage them to do their best.",
  },
  {
    id: 7,
    text: "You are taking your pregnant sister from your village to a nearby town for her regular checkup. Suddenly, while crossing a jungle area, you see a person snatching a lady's chain and run away from the train. You...",
    response:
      "Inform the train staff and authorities about the incident for necessary action.",
  },
  {
    id: 8,
    text: "You got on the Mumbai Express from Chennai Central at platform no. 5, where you have to go to Hyderabad Express, which usually stands at platform no. 1. The train started, and you come to know that when the TT asks you for a ticket. You...",
    response:
      "Explain the situation to the TT and request guidance for the next course of action.",
  },
  {
    id: 9,
    text: "He didn't find a subject interesting to study, so he...",
    response:
      "Explore different subjects or study approaches to find motivation and interest.",
  },
  {
    id: 10,
    text: "He was rather young when his father was killed in the war, and later his mother was kidnapped by the rival group. He had no other relative. He...",
    response:
      "Seek assistance from authorities or organizations that can help in such situations.",
  },
  {
    id: 11,
    text: "If some of your friends crack a joke on you in the presence of your girlfriend, what will you do...",
    response:
      "Take it lightly, maintain a sense of humor, and respond in a friendly manner.",
  },
  {
    id: 12,
    text: "While shooting a film, terrorists kidnapped the heroine. You are in charge of the 'check post' nearby the scene. What will you do...",
    response:
      "Immediately inform the authorities and provide them with all the necessary information.",
  },
  {
    id: 13,
    text: "A stocky built ruffian snatched the chain of the wife of a soldier. You are passing by. What do you do?",
    response:
      "Alert nearby, catch the ruffian, return the chian and hand him over to the police.",
  },
  {
    id: 14,
    text: "You had a hot discussion with your friend on a certain point. Later you found that your friend was correct. You...",
    response:
      "Admit your mistake, apologize, and appreciate your friend's perspective.",
  },
  {
    id: 15,
    text: "Some passengers shook you out of sleep and asked for some money because they were pickpocketed. You...",
    response:
      "Assess the situation and help them if it seems genuine or inform the authorities.",
  },
  {
    id: 16,
    text: "He is in charge of the mess and finds that mess funds are being misused. He...",
    response:
      "Investigate the matter discreetly and take appropriate action to stop the misuse.",
  },
  {
    id: 17,
    text: "He is studying for his exams in a room, and an acquaintance comes in and gets comfortable. He...",
    response:
      "Request the acquaintance to maintain a quiet environment for studying.",
  },
  {
    id: 18,
    text: "He desires to make national plans a great success for the nation. What should he do...",
    response:
      "Identify key areas of improvement, collaborate with relevant stakeholders, and implement effective strategies.",
  },
  {
    id: 19,
    text: "In order to produce better results in his organization, what measures should he take...",
    response:
      "Analyze the existing processes, identify areas for improvement, and implement necessary changes.",
  },
  {
    id: 20,
    text: "He lost the election. Why...",
    response: "Weak campaign strategy.",
  },
  {
    id: 21,
    text: "He met a serious accident while going on a bike, resulting in a serious injury to the pillion rider and serious damage to his vehicle. He...",
    response:
      "Ensure immediate medical help for the injured and inform the authorities about the accident.",
  },
  {
    id: 22,
    text: "He saw fire in a city building, and people were panicking. He...",
    response:
      "Quickly inform the fire department and help evacuate people from the building.",
  },
  {
    id: 23,
    text: "Just before reaching the mountain or peak, he found his feet were slipping due to sand/snow underneath his feet. He...",
    response:
      "Tread carefully, use proper equipment for support, and ensure safety while climbing.",
  },
  {
    id: 24,
    text: "While returning from range practice, he found one of his men in possession of live ammunition. He...",
    response:
      "Secure the ammunition, investigate the situation, and take necessary disciplinary action.",
  },
  {
    id: 25,
    text: "He was assigned the task of capturing terrorists at midnight. They were hiding in a hut in a village nearby. He...",
    response:
      "Coordinate with the appropriate authorities, plan a strategic operation, and ensure the safety of everyone involved.",
  },
  {
    id: 26,
    text: "He is superseded for promotion, although he feels he is fit for promotion in all respects. He...",
    response:
      "Seek feedback from superiors , identify areas of improvement, and work towards enhancing skills and qualifications.",
  },
  {
    id: 27,
    text: "What sort of programs do you like to watch on TV and online?",
    response:
      "I enjoy watching a variety of genres, including drama, documentaries, and comedy.",
  },
  {
    id: 28,
    text: "An angel grants a wish to you. What would you desire?",
    response:
      "It would depend on my current aspirations and needs at the time.",
  },
  {
    id: 29,
    text: "He was not getting enough salary for his job. He...",
    response:
      "Consider discussing the issue with the employer, exploring other job opportunities, or seeking ways to enhance skills for better prospects.",
  },
  {
    id: 30,
    text: "You went to the library for a very urgent book and found that all its copies were already issued to others. You...",
    response:
      "Inquire about the availability of the book in nearby libraries or explore online resources as an alternative.",
  },

  {
    id: 31,
    text: "Generally people do not listen to his arguments. What should he do...",
    response:
      "Strengthen his communication skills and find more persuasive ways to present his arguments.",
  },
  {
    id: 32,
    text: "He was asked to walk a long distance by his scout-master but he was having fever. He...",
    response:
      "Inform his scout-master about his condition and seek alternative arrangements.",
  },
  {
    id: 33,
    text: "While travelling in the bus some people started arguing with her. She...",
    response:
      "Remain calm, avoid confrontation, and seek assistance from the bus conductor or authorities if necessary.",
  },
  {
    id: 34,
    text: "He was to carry some valuable from one place to another and there was danger of dacoits on the way. He...",
    response:
      "Take necessary precautions, such as informing the authorities, traveling with a group, or hiring security personnel.",
  },
  {
    id: 35,
    text: "She found her parents quarreling over her future. She...",
    response:
      "Try to understand their concerns, communicate her own aspirations, and seek a constructive solution.",
  },
  {
    id: 36,
    text: "He has been served with a challenge by the college bully because he had reported against him to the Principal when the latter was trying to beat up his friend. He...",
    response:
      "Consider discussing the matter with a trusted authority figure, seeking support from friends, or finding peaceful resolution strategies.",
  },
  {
    id: 37,
    text: "He was doing private tuitions in order to finance his higher studies as his father was a poor man. When the exam drew near, he found he was not fully prepared. Therefore, he...",
    response:
      "Devote extra time for focused studies, seek guidance from mentors or teachers, and make use of available resources for better preparation.",
  },
  {
    id: 38,
    text: "When she was going to the market, it started raining and all the streets were flooded in no time. She...",
    response:
      "Take shelter, wait for the rain to subside, or find an alternative route to reach the market.",
  },
  {
    id: 39,
    text: "Since he was not interested in the subject, he found it hard to concentrate on his studies. He...",
    response:
      "Find ways to make the subject more engaging, seek help from teachers or peers, and develop effective study techniques.",
  },
  {
    id: 40,
    text: "When you were on a NCC camp, your batch declared a strike because of the rude behaviour of the Havildar. So you...",
    response:
      "Evaluate the consequences, seek guidance from authorities, and consider alternative ways to address the issue without compromising discipline.",
  },
  {
    id: 41,
    text: "He sees a rifle disc lying in the football field of his company. He...",
    response:
      "Immediately inform the appropriate authorities about the discovery and ensure the proper handling of the rifle disc.",
  },
  {
    id: 42,
    text: "While passing through a mountainous track he was challenged by two persons with weapons in their hands. He...",
    response:
      "Assess the situation, try to defuse tension through peaceful communication, and seek help from nearby authorities or security personnel.",
  },
  {
    id: 43,
    text: "The Station HQ assured all facilities at the sight for the tree plantation but when he reached with ten men, no arrangements were seen to have been made. He  ...",
    response:
      "Contact the Station HQ to address the issue, find alternative arrangements if possible, and focus on finding a solution to carry out the tree plantation effectively.",
  },
  {
    id: 44,
    text: "He was convoy Commander. On the way he learnt that there was a landslide. He...",
    response:
      "Assess the situation, prioritize the safety of the convoy, and find alternate route for the safe passage of the convoy.",
  },
  {
    id: 45,
    text: "Although there was a confirmed ticket, the conductor was refusing him entry to the compartment and the train was about to leave. He...",
    response:
      "Request assistance from railway authorities, show the confirmed ticket, and try to resolve the issue with the conductor.",
  },
  {
    id: 46,
    text: "He was going on temp duty in a military vehicle when he suddenly developed a fever. He...",
    response:
      "Inform the relevant authorities about the situation, seek medical attention if required, and follow the protocols for handling such circumstances.",
  },
  {
    id: 47,
    text: "He was performing the duties of MES NCO and MES staff refused to attend to his complaints. He...",
    response:
      "Bring the issue to the attention of higher authorities, follow the proper channels for complaint resolution, and document the instances of refusal for evidence.",
  },
  {
    id: 48,
    text: "While he was returning to his unit location in an auto rickshaw, a car hit his vehicle from the rear and he was thrown on the footpath. He...",
    response:
      "Seek medical attention, report the incident to the appropriate authorities, and gather necessary evidence for insurance or legal purposes.",
  },
  {
    id: 49,
    text: "While he was studying in school, his father died, leaving behind his brother and sister. He...",
    response:
      "Seek emotional support from family, friends, or counselors, explore available resources for financial assistance, and develop a plan to manage responsibilities effectively.",
  },
  {
    id: 50,
    text: "After getting tired, he wanted to sleep whereas he was asked to accompany a long-range patrol with his Sub Maj. He...",
    response:
      "Evaluate the urgency and importance of the long-range patrol, communicate his tiredness to the Sub Maj, and explore alternative solutions or compromises.",
  },
  {
    id: 51,
    text: "In a debate when her turn came, all spectators walked away. She...",
    response:
      "Maintain composure, focus on presenting her arguments effectively.",
  },
  {
    id: 52,
    text: "You have not fared well in the written test for admission to NDA. Your father advises you to give up the idea and do something else. You will...",
    response:
      "Consider my aspirations, weigh the options, seek guidance from mentors, and make a well-informed decision based on my aspirations.",
  },
  {
    id: 53,
    text: "You happen to be witnessing a demonstration staged by one of the political parties on a canal bridge. Police used tear gas and a shell fell close to you. There is no way to get out. You will...",
    response:
      "Stay calm, seek shelter or cover, follow instructions from authorities, and wait for the situation to improve or for assistance to arrive.",
  },
  {
    id: 54,
    text: "While sleeping at night, he heard a dreadful cry. On inquiring, he finds that his neighbor was lying unconscious with a knife in his chest. He...",

    response:
      "Immediately call for medical help, avoid tampering with the crime scene, and cooperate with law enforcement authorities for investigation.",
  },
  {
    id: 55,
    text: "Your company is camping in a jungle area for training where shooting is prohibited. A leopard who may prove dangerous is seen close to the camp. As a sentry on duty, you will...",
    response:
      "Inform the camp authorities immediately, ensure the safety of personnel, and follow the established protocols for dealing with wildlife encounters.",
  },
  {
    id: 56,
    text: "While walking on the roadside, you accidentally brush against another person who slaps you. You will...",
    response:
      "Stay composed, avoid escalating the situation, consider walking away, and seek help from authorities if necessary.",
  },
  {
    id: 57,
    text: "You find that you have to share your desk in school with another boy who is of a quarrelsome nature. You...",
    response:
      "Try to establish a peaceful and cooperative relationship, set boundaries, and communicate with teachers or authorities if conflicts arise.",
  },
  {
    id: 58,
    text: "Whenever you go out for a stroll in the evening, your father gets annoyed with you. You...",
    response:
      "Have a respectful conversation with your father to understand his concerns, find a middle ground, or address any safety-related issues to alleviate his worries.",
  },
  {
    id: 59,
    text: "You and your father have different political leanings, which often lead to hot discussions. You...",
    response:
      "Engage in respectful dialogue, listen to each other's perspectives, find common ground, and prioritize maintaining a healthy relationship over winning arguments.",
  },
  {
    id: 60,
    text: "He is given a job by his boss, which he feels is illegitimate. He will...",
    response:
      "Evaluate the ethical implications of the job, seek guidance from mentors or legal experts, and consider raising concerns through appropriate channels or refusing the task respectfully.",
  },
];

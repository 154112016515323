import React from "react";
import { quotes } from "./Quotes";

const QuoteDisplay = () => {
  return (
    <p
      style={{
        color: "#4CAF50",
        width: "80%",
        margin: "auto",
      }}
    >
      {quotes[Math.floor(Math.random() * quotes.length)]}
    </p>
  );
};

export default QuoteDisplay;

import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../navbar/navbar";
import { Helmet } from "react-helmet";

import "../instruction.css";

const PIInstructions = () => {
  return (
    <div>
      <Helmet>
        <title>
          Personal Interview - Service Selection Board(SSB) Personal
          Interview(PI) Questions and Answers
        </title>
        <meta
          name="description"
          content="  Personal Interview - Service Selection Board(SSB) Personal Interview(PI) Questions and Answers"
        />

        <link
          rel="canonical"
          href="https://www.troppers.com/personal-interview/instruction"
        />
      </Helmet>
      <div>
        <Navbar />
      </div>

      <div className="instruction">
        <div className="ins_header">
          <h1>PI Instructions</h1>
        </div>
        <div className="ins_body">
          <p>
            1. This Module Consists of All the Expected Personal Interview
            Questions with It's Answers and Explanations.
          </p>
          <p>2. Click on the Question to see the answer and Explanations.</p>
          <p style={{ color: "red" }}>
            Note - The provided responses should not be memorized line by line
            as each candidate's situation is unique and requires a tailored
            response. Rather, this module should be utilized as a resource for
            learning proper formatting and presentation techniques for one's
            responses.
          </p>
        </div>
        <div className="ins_footer">
          <Link
            to="/service-selection-board-ssb/personal-interview-pi-questions-and-answers"
            className="btn_next"
          >
            Start
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PIInstructions;

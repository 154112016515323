export const situationsEight = [
  {
    id: 1,
    text: "You had a hot discussion with your friend on certain point. Later you found that your friend was correct. You...",
    response:
      "Apologize to your friend, acknowledge their correctness, and learn from the experience.",
  },
  {
    id: 2,
    text: "You are driving a car in a highway at full speed, suddenly brakes fail. You...",
    response:
      "Stay calm, engage emergency brake, and attempt to slow down the car safely.",
  },
  {
    id: 3,
    text: "You were travelling by bus, suddenly the engine caught fire. You...",
    response:
      "Alert the driver and fellow passengers, evacuate the bus, and call emergency services.",
  },
  {
    id: 4,
    text: "His brother was becoming more short-tempered and quarrelsome after their father’s death. He...",
    response:
      "Have a heart-to-heart conversation, offer support, and encourage seeking professional help if necessary.",
  },
  {
    id: 5,
    text: "He was a good player in his college hockey team but not selected as the captain. He...",
    response:
      "Accept the decision, support the captain, and continue to contribute to the team.",
  },
  {
    id: 6,
    text: "While going to college, a few boys were chasing her. She...",
    response:
      "Find a safe location, seek help from authorities or bystanders, and report the incident.",
  },
  {
    id: 7,
    text: "He was on his way to attend his friend's marriage when an old man rushed towards his car for help. He...",
    response:
      "Stop the car, assess the situation, and provide necessary assistance to the old man.",
  },
  {
    id: 8,
    text: "His best friend is asking him to join for a birthday party but he has work at home. He...",
    response: "Complete the work early and join the birthday party.",
  },
  {
    id: 9,
    text: "He was on his way to college when he saw a few men entering a bank and heard a gunshot. He...",
    response:
      "Ensure personal safety, report the incident to authorities, and cooperate with their instructions.",
  },
  {
    id: 10,
    text: "He borrowed money from a colleague to help his poor friend, and now his friend is missing. He...",
    response:
      "Search for the friend, inform the colleague, and seek assistance from the police if necessary.",
  },
  {
    id: 11,
    text: "His neighbors were quarreling over the partition wall erected between their houses. He...",
    response:
      "Mediate a peaceful resolution, listen to both parties, and suggest compromises.",
  },
  {
    id: 12,
    text: "While standing in the corridor of his house on the 1st floor, he saw a child falling from their 1st floor house. He...",
    response: "Try to catch the child and save him.",
  },
  {
    id: 13,
    text: "If his friend hits him, he...",
    response:
      "Stay calm, assess the situation, and try to resolve the issue through peaceful communication.",
  },
  {
    id: 14,
    text: "There is a famine in his city, and people are dying of hunger. He...",
    response:
      "Initiate or support relief efforts, donate to organizations providing aid, and raise awareness.",
  },
  {
    id: 15,
    text: "Soon after his training, he was posted in the forward areas of the high mountains. He...",
    response:
      "Adapt to the challenging environment, follow protocols, and fulfill his duty with resilience.",
  },
  {
    id: 16,
    text: "She had quarreled with her neighbor yesterday, but today she needs his help. She...",
    response:
      "Put differences aside, approach the neighbor politely, and request assistance.",
  },
  {
    id: 17,
    text: "Due to heavy rush, he dashed into a girl on the railway platform who fell on the track, and a train was approaching on the same track. He...",
    response:
      "React quickly, pull the girl to safety, and alert others about the oncoming train.",
  },
  {
    id: 18,
    text: "While walking on the road, he found a purse with ₹50 and some ID and bills. He...",
    response:
      "Locate the owner using the ID or contact authorities, and return the purse and its contents.",
  },
  {
    id: 19,
    text: "After great effort, he got a private job, but the salary was less. He...",
    response:
      "Assess the situation, seek opportunities for growth, and consider discussing the salary concern.",
  },
  {
    id: 20,
    text: "Due to a tsunami, his family lost all their possessions and needed a fresh start. As the eldest son, he...",
    response:
      "Support the family emotionally, explore options for shelter and basic needs, and assist in rebuilding.",
  },
  {
    id: 21,
    text: "During the exams, he saw his teacher helping some students write answers. He...",
    response:
      "Maintain integrity, report the incident to the relevant authorities.",
  },
  {
    id: 22,
    text: "Your friend needs urgent money from you for his father’s operation, and you found a purse full of money along with the owner's ID. You will...",
    response:
      "Use the ID to locate the owner, explain the situation, and return the purse or offer assistance.",
  },
  {
    id: 23,
    text: "Your ten-year-old sister, who normally returns from her school by 5 P.M., has not come back; it is past 6 P.M. What would you do...",
    response:
      "Check with her friends or school, inform parents and authorities, and initiate a search if necessary.",
  },
  {
    id: 24,
    text: "The three most important qualities of an Army Officer’s personality are...",
    response: "Leadership, integrity, and resilience.",
  },
  {
    id: 25,
    text: "You have received a telegram that your grandfather, who was staying alone in your native village, has expired. Your father is out of station, and there is not enough money at home to undertake the journey. You...",
    response:
      "Reach out to relatives or friends for support, reach the village and perform all rites.",
  },
  {
    id: 26,
    text: "You are standing among a group of friends. One of them cracks a joke at your expense. You...",
    response:
      "Take it in a good spirit, laugh along, and respond playfully if comfortable.",
  },
  {
    id: 27,
    text: "You think that the most important thing in life is...",
    response:
      "Subjective and can vary, but values like happiness, love, and personal growth are often considered important.",
  },
  {
    id: 28,
    text: "Ram won an expensive watch in a lottery but found it defective. What would he do...",
    response:
      "Contact the lottery organizers or the manufacturer to seek a replacement or repair.",
  },
  {
    id: 29,
    text: "A friend of yours is going to the U.S.A. for studies for a period of four years. You want to give him a present. You...",
    response:
      "Select a meaningful gift, such as a token of friendship or something useful for their studies.",
  },
  {
    id: 30,
    text: "There was her exam the next day, and today her father had a heart attack. She...",
    response:
      "Prioritize her father's health, seek medical assistance, and inform concerned authorities about the exam situation.",
  },
  {
    id: 31,
    text: "A man had fallen into the sea when the sea was rough. He...",
    response:
      "Alert nearby authorities or lifeguards, attempt a rescue while ensuring personal safety.",
  },
  {
    id: 32,
    text: "His brother died, leaving his wife and two children behind. He...",
    response:
      "Support his sister-in-law and nephews emotionally, assist in necessary arrangements, and offer help.",
  },
  {
    id: 33,
    text: "While carrying out his duties in Nagaland, he lost his route and had no food supply for the next 48 hours. He...",
    response:
      "Stay calm, seek assistance from fellow personnel, and adapt to the situation until help arrives.",
  },
  {
    id: 34,
    text: "On reaching the railway station, you find that the train you wanted to catch is about to start, and there is hardly any time to purchase the ticket. You...",
    response: "Board the train and pay fine to TT.",
  },
  {
    id: 35,
    text: "He and his friends are required to go to the other side of the bridge, but the bridge is not in good condition. He...",
    response:
      "Assess the risks, seek alternative routes or transportation options, and prioritize safety.",
  },
  {
    id: 36,
    text: "While traveling in a train, he finds on one side two ladies quarreling and on the other side a briefcase lying unclaimed in the compartment. He...",
    response:
      "First, attend to the quarrel, defuse the situation, and then inform the authorities about the unclaimed briefcase.",
  },
  {
    id: 37,
    text: "He observes that one helicopter has fallen in the vicinity of his school. He...",
    response:
      "Ensure personal safety, inform relevant authorities about the incident, and follow their instructions.",
  },
  {
    id: 38,
    text: "While walking fast, he dashed into a school child who fell down. He...",
    response:
      "Apologize, help the child get up, and ensure they are not injured before continuing on.",
  },
  {
    id: 39,
    text: "One of his classmates was jealous of his studies and spread rumors about him. He...",
    response:
      "Remain focused on studies, ignore the rumors, and maintain a positive attitude.",
  },
  {
    id: 40,
    text: "Tomorrow was her exam, and today she missed her admit card. She...",
    response: "Download new one from the website.",
  },
  {
    id: 41,
    text: "Bharat Bandh is likely to be declared while you are on leave. Your leave is to be finished two days after the commencement of Bandh. You...",
    response:
      "Contact your superiors or workplace, inform them about the situation, and seek their advice on the next steps.",
  },
  {
    id: 42,
    text: "You have to go for patrolling duty in field. Your driver is having high fever. He...",
    response:
      "Arrange for a replacement driver or seek alternative transportation options.",
  },
  {
    id: 43,
    text: "You are required to dig trenches, but your colleagues are not co-operating with you. He..",
    response:
      "Communicate the importance of teamwork, and seek their cooperation.",
  },
  {
    id: 44,
    text: "While working in the kitchen garden, rain starts. He...",
    response: "Seek shelter or protective cover until the rain subsides.",
  },
  {
    id: 45,
    text: "As a secretary of Student Union of his college. She will do the following...",
    response:
      "Organize events, communicate student concerns, and represent the student body.",
  },
  {
    id: 46,
    text: "You were going to the back of your apartment side and saw few people taking liquor. You will...",
    response:
      "Inform the appropriate authorities about the situation or report any disturbance.",
  },
  {
    id: 47,
    text: " On the weekend highway trip, you are running the car at high speed which hits a small child playing near the road. You...",
    response:
      "Stop the car, check the child's condition, provide immediate medical assistance, and inform emergency services.",
  },
  {
    id: 48,
    text: "The meteorological department warns your district that a big storm is going to hit. You will...",
    response:
      "Take necessary precautions, secure belongings, and ensure safety of yourself and others.",
  },
  {
    id: 49,
    text: "Your sister is preparing for her exams and the light suddenly switched off in your apartment. You...",
    response:
      "Find alternative lighting sources or arrange for a backup power supply.",
  },
  {
    id: 50,
    text: "When traveling in a train, you found some passengers taking liquor in front of all. You...",
    response:
      "Notify the train authorities or authorities at the next station about the situation.",
  },
  {
    id: 51,
    text: "While traveling in a train, you see a group of sannyasis taking Ganja near the washbasin, causing breathing trouble to passengers. You...",
    response:
      "Inform the train authorities or authorities at the next station about the situation.",
  },
  {
    id: 52,
    text: "You are in serious love with a girl/boy, and while you return late from the 2nd show of a film, you saw her/him with your neighbor. You will...",
    response:
      "Approach the person calmly, communicate your feelings, and seek clarification.",
  },
  {
    id: 53,
    text: "You are going to catch a train by 10:30 AM, it's already 10 AM. The usual journey time is half an hour. On the way, you saw an old lady getting fainted on the road while crossing it. You...",
    response:
      "Stop and provide immediate assistance to the old lady, call for medical help, and continue for the journey.",
  },
  {
    id: 54,
    text: "While practicing X-Country, he sprained his ankle. The final was 2 days away. He...",
    response:
      "Seek medical attention, rest, and follow the prescribed treatment to recover.",
  },
  {
    id: 55,
    text: "In the train, two dacoits were looting everybody. They demanded her belongings also.She...",
    response:
      "Remain calm, comply with their demands, and later report the incident.",
  },
  {
    id: 56,
    text: "He was to give a lecturette to his colleagues, and the CO was to attend. He found it difficult to get details on the given topic. He...",
    response:
      "Research and gather relevant information, prepare a concise presentation, and practice delivery.",
  },
  {
    id: 57,
    text: "He came to know that some of his friends were trying to sell a tarpaulin that was spare to collect money for Barakhana. He...",
    response:
      "Discuss with friends, express concerns about the selling of spare items, and suggest alternative fundraising methods.",
  },
  {
    id: 58,
    text: "As CQMH of his company, he found that the rations were not of good quality. He...",
    response:
      "Report the issue to higher authorities, document evidence, and ensure the well-being of the personnel.",
  },
  {
    id: 59,
    text: "He was the leader of a trekking group and found the route dangerous due to heavy snowfall. It was getting dark. He...",
    response:
      "Assess the situation, prioritize safety, consider alternative routes, and manage the group accordingly.",
  },
  {
    id: 60,
    text: "The examination was nearing, and he found it difficult to understand the Math syllabus. He...",
    response:
      "Seek help from teachers or classmates, study systematically, and practice problem-solving.",
  },
];

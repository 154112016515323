import React, { useState, useRef } from "react";
import { qna } from "./qna";
import Navbar from "../../navbar/navbar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./personalInterview.css";
import Footers from "../../footer/Footers";

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq, isPremium, num } = props;
  const { question, id, answer } = faq;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h6>
            {" "}
            <span>Q{id}</span>.
          </h6>
          <h5 className="rc-accordion-title">{question}</h5>
          <i
            style={{
              color: `${
                isPremium || num === 0 || num === 1 || num === 2
                  ? "green"
                  : "red"
              }`,
            }}
            className="fa fa-chevron-down rc-accordion-icon"
          ></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          {/* <p className="mb-0">{answer}</p> */}
          {isPremium || num === 0 || num === 1 || num === 2 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: answer,
              }}
            />
          ) : (
            <p>Upgrade To Premium</p>
          )}
        </div>
      </div>
    </div>
  );
};

const PersonalInterview = () => {
  const auth = useSelector((state) => state.auth);
  const isPremium = auth?.user?.payment?.isPaid;
  const [active, setActive] = useState(null);
  const history = useHistory();

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else if (
      isPremium ||
      index === 0 ||
      index === 1 ||
      index === 2 ||
      index === 3
    ) {
      setActive(index);
    } else if (!isPremium) {
      history.push("/premium");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="card">
              <div className="card-body">
                <h4 className="form-heading mb-4 text-primary text-center mt-3">
                  Personal Interview QnA
                </h4>
                {qna.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                      num={index}
                      isPremium={isPremium}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footers />
    </>
  );
};

export default PersonalInterview;

import React from "react";
import { lecturette } from "./content";
import { useParams } from "react-router-dom";
import Navbar from "../../navbar/navbar";
import "./lecturette.css";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const IndividualLecturette = () => {
  const auth = useSelector((state) => state.auth);
  const isPremium = auth?.user?.payment?.isPaid;
  const { title } = useParams();
  const articles = lecturette.filter((content) => {
    return content.title.replace(/\s/g, "-") === title;
  });

  return (
    <>
      <Navbar />
      <div>
        {articles.map((article, index) => {
          if (
            isPremium ||
            article.id === 1 ||
            article.id === 2 ||
            article.id === 3
          ) {
            return (
              <div className="article-content" key={index}>
                <h1 className="article-title">{article.title}</h1>
                <img src={article.img} alt={article.title} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.content,
                  }}
                />
              </div>
            );
          } else {
            return <Redirect to="/premium" />;
          }
        })}
      </div>
    </>
  );
};

export default IndividualLecturette;

export const situationsSeven = [
  {
    id: 1,
    text: "You are the DM of a district and all the hospitals in your district are running out of oxygen cylinders. You...",
    response:
      "Coordinated with higher authorities and mobilized resources to ensure timely supply of oxygen cylinders to hospitals.",
  },
  {
    id: 2,
    text: "While going to her college, she noticed an accident on a 'U' turn of the road between a motorcycle and a car. The motorcyclist is profusely bleeding from his head. She...",
    response:
      "Called emergency services, provided first aid to the motorcyclist, and stayed with him until medical help arrived.",
  },
  {
    id: 3,
    text: "He had boarded a wrong train and came to know only when he was asked to pay money to the TT. He...",
    response:
      "Explained the situation to the TT, apologized for the mistake, and sought guidance to reach his intended destination.",
  },
  {
    id: 4,
    text: "He saw his girlfriend walking with another person on his way. He...",
    response:
      "Approached his girlfriend calmly to discuss the situation and understand the context.",
  },
  {
    id: 5,
    text: "His boss gives a piece of work and orders him to follow his instructions to complete the work, but the work is difficult to finish within time. He...",
    response:
      "Communicated with the boss to discuss the feasibility of completing the work within the given time frame and proposed alternative solutions if necessary.",
  },
  {
    id: 6,
    text: "He has to go to a city with cash for work, but the way is dangerous because of dacoits. He...",
    response:
      "Evaluated the risks, considered alternative modes of transportation or security measures, and took necessary precautions to ensure his safety during the journey.",
  },
  {
    id: 7,
    text: "He was asked by his instructor to walk a long distance when he was having a fever. He...",
    response:
      "Informed the instructor about his health condition and requested for suitable alternatives or accommodations considering his illness.",
  },
  {
    id: 8,
    text: "He was asked to speak in a debate on whether student union should continue. He...",
    response:
      "Researched and prepared arguments for both sides of the debate, presented a balanced perspective, and encouraged constructive discussion.",
  },
  {
    id: 9,
    text: "While preparing for his examination, his sick father needed a lot of attention. He...",
    response:
      "Managed his time effectively, prioritized his responsibilities, and ensured both his father's care and his exam preparation were taken care of.",
  },
  {
    id: 10,
    text: "He was walking on a dark street with his girlfriend when suddenly 10 armed people came and started harassing his girlfriend. The nearest police station was 10km away...",
    response:
      "Ensured his girlfriend's immediate safety, encouraged her to stay calm, and found a safe location to seek help or contact the police.",
  },
  {
    id: 11,
    text: "There is a countrywide lockdown, and you are stuck in an unknown town, and you are also running out of money. You...",
    response:
      "Reached out to local authorities or helplines for assistance, explored options for temporary accommodation and financial support, and followed government guidelines during the lockdown.",
  },
  {
    id: 12,
    text: "His sister’s marriage is fixed, but she is not happy to marry the person not of her choosing. He...",
    response:
      "Engaged in open and supportive conversations with his sister and family members, understanding her concerns, and explored possible solutions that respect her choices.",
  },
  {
    id: 13,
    text: "He wants to join the Indian Army, whereas his father wants him to become a software professional. He...",
    response:
      "Had an open discussion with his father, considering both career options, and made an informed decision based on personal aspirations and future prospects.",
  },
  {
    id: 14,
    text: "She has just passed graduation with science. Due to a loss of business, her family has fallen under financial crisis. She would...",
    response:
      "Explored job opportunities related to her field of study, considered alternative sources of income, and supported her family in navigating the financial crisis.",
  },
  {
    id: 15,
    text: "On his way, he saw a man suddenly falling down on the road. On reaching him, he comes to know that he is a heart patient. He...",
    response:
      "Immediately called for medical assistance, ensured the person's comfort, and provided any necessary first aid while waiting for professional help to arrive.",
  },
  {
    id: 16,
    text: "His parents wanted him to marry a wealthy and less educated girl, but he had already found a suitable educated girl for himself. He...",
    response:
      "Engaged in open and respectful dialogue with his parents, expressing his feelings and emphasizing the importance of compatibility and shared values in a life partner.",
  },
  {
    id: 17,
    text: "A fellow passenger on the train objected to his smoking, being an offense in a public place. He...",
    response:
      "Respected the fellow passenger's concern, apologized, and refrained from smoking in the public place, abiding by the rules and showing consideration for others.",
  },
  {
    id: 18,
    text: "He reached home from the office and saw his house on fire. He...",
    response:
      "Immediately called the fire department, evacuated any people or pets present, and followed safety protocols while waiting for the fire department's arrival.",
  },
  {
    id: 19,
    text: "While he was going up in a lift, the electric power supply failed. He...",
    response:
      "Remained calm, pressed the emergency button or called for help through the lift intercom, and waited for assistance from maintenance or emergency services.",
  },
  {
    id: 20,
    text: "While he was hosting a dinner for his friends in a hotel, he realized that he has forgotten his wallet at home. He...",
    response:
      "Apologized to his friends, explained the situation to the hotel staff, provided necessary identification, and arranged for an alternative payment method.",
  },
  {
    id: 21,
    text: "He is on annual leave at his village. One day, dacoits started looting his village and also killed a villager. He...",
    response:
      "Contacted the local authorities or police, informed them about the situation, and provided any relevant information to support their investigation and efforts.",
  },
  {
    id: 22,
    text: "His name was raised in a crackdown in college. He...",
    response:
      "Cooperated with the authorities, provided any required information, and followed the due process to clarify his involvement or resolve any misunderstandings.",
  },
  {
    id: 23,
    text: "He was waiting in the queue to buy a movie ticket when a well-built person went to the window to get the ticket. He...",
    response:
      "Waited patiently for his turn, respecting the queue order, and maintained civility in the ticket-buying process.",
  },
  {
    id: 24,
    text: "He was on a cycling expedition with his friends when he saw some miscreants with local weapons heading to an area. He...",
    response:
      "Ensured the safety of himself and his friends by alerting the authorities or nearby law enforcement about the presence of the miscreants.",
  },
  {
    id: 25,
    text: "He found his juniors amending company records to leak out some money. He...",
    response:
      "Reported the misconduct to the appropriate authorities within the company, ensuring the integrity of financial records and promoting a culture of honesty.",
  },
  {
    id: 26,
    text: "His brother was facing charges for the murder which he really committed. He...",
    response:
      "Encouraged his brother to take responsibility for his actions, cooperate with the authorities, and seek legal representation to ensure a fair trial.",
  },
  {
    id: 27,
    text: "He saw his best friend copying from a book in the examination hall. He...",
    response:
      "Reminded his best friend about the importance of academic integrity, encouraged him to rely on his own knowledge, and discouraged cheating.",
  },
  {
    id: 28,
    text: "He was trying long jump on college sports day and injured his ankle. He...",
    response:
      "Sought immediate medical attention, followed the advice of medical professionals, and prioritized his recovery and well-being.",
  },
  {
    id: 29,
    text: "While cooking, her mother started shouting 'snake snake'. She would...",
    response:
      "Ensured the safety of herself and her mother by calmly assessing the situation, taking necessary precautions, and seeking professional help to remove the snake safely.",
  },
  {
    id: 30,
    text: "He found his hostel roommate not very friendly with him. He...",
    response:
      "Initiated friendly conversations, tried to find common interests, and fostered a positive and respectful relationship with his roommate.",
  },
  {
    id: 31,
    text: "If some of your friend cracks a joke on you in presence of your girlfriend, what will you do...",
    response:
      "Laugh along and playfully respond, showing that you have a good sense of humor.",
  },
  {
    id: 32,
    text: "In an exhibition, a fake company is attracting the crowd by false propaganda. He knows the reality. He will...",
    response:
      "Inform the exhibition organizers or authorities about the fake company's false propaganda.",
  },
  {
    id: 33,
    text: "An airplane crashed in a field nearby your battalion area. You are battalion commander. What steps would you take at such a critical juncture...",
    response:
      "Mobilize the necessary resources, establish a perimeter for safety, coordinate with emergency services, and provide support and assistance to the affected individuals.",
  },
  {
    id: 34,
    text: "He was watching a movie in the cinema hall. He sees a snake in front of his legs. He...",
    response:
      "Remain calm, slowly and quietly move away from the snake, and inform the cinema hall staff or authorities about the situation.",
  },
  {
    id: 35,
    text: "What if you and your friend fall in love with the same girl...",
    response:
      "Have an open and honest conversation with your friend, prioritize the friendship, and respect the girl's choice in the matter.",
  },
  {
    id: 36,
    text: "He is going to the examination hall from home by scooter. On the way, two policemen stop him and ask him to go to the police station as the scooter is registered in a different state. He...",
    response:
      "Politely explain the situation to the policemen, provide necessary documents, and comply with their instructions while clarifying any misunderstandings.",
  },
  {
    id: 37,
    text: "He is going to a restaurant with his girlfriend. After entering, he notices that he forgot his wallet. He...",
    response:
      "Apologize to his girlfriend, explain the situation to the restaurant staff, provide necessary identification, and offer to return with the payment or find an alternative solution.",
  },
  {
    id: 38,
    text: "He is carrying money to disburse the salary of his employees. Two miscreants surround him and ask him to surrender money. He...",
    response:
      "Prioritize personal safety, remain calm, and cooperate with the miscreants while looking for an opportunity to seek help or inform the authorities discreetly.",
  },
  {
    id: 39,
    text: "He is appearing in an examination and finds it hard to answer the questions. He...",
    response:
      "Stay focused, manage time effectively, attempt the questions to the best of his ability, and avoid getting overwhelmed by seeking assistance from invigilators if necessary.",
  },
  {
    id: 40,
    text: "While going for trekking, he finds that his team has forgotten food material at the base camp. He...",
    response:
      "Assess the situation, explore alternative food sources in the trekking area, and communicate with the team to find a suitable solution for sustenance.",
  },
  {
    id: 41,
    text: "He was appointed to supervise evening games in the college, but he was staying far away. He...",
    response:
      "Plan his schedule accordingly, ensure timely arrival at the college, and find ways to manage the distance effectively to fulfill his supervisory responsibilities.",
  },
  {
    id: 42,
    text: "The love affair of her elder brother has been converted into a physical relationship with a girl whom he would like to marry. Parents are dead against this marriage. She would...",
    response:
      "Encourage open and respectful communication within the family, understand everyone's perspective, and support her brother in making informed decisions while considering the well-being of all involved.",
  },
  {
    id: 43,
    text: "He had undergone a major surgical operation, but there was no one to look after. He...",
    response:
      "Seek assistance from medical professionals or caregivers, inform the hospital staff about the situation, and make necessary arrangements for post-operative care and support.",
  },
  {
    id: 44,
    text: "His parents were insisting on his early marriage, but he wanted to take up a job first. He...",
    response:
      "Communicate his aspirations and goals to his parents, explain the importance of pursuing a career before marriage, and seek their understanding and support.",
  },
  {
    id: 45,
    text: "Hearing an unusual sound at night, he woke up and found a thief jumping out of his window. He...",
    response:
      "Ensure personal safety, avoid confrontation, call the police or emergency helpline, provide them with necessary information, and cooperate with them in the investigation.",
  },
  {
    id: 46,
    text: "He urgently needed some money. He...",
    response:
      "Consider available options such as borrowing from a trusted source, seeking financial assistance from relevant organizations, or exploring part-time job opportunities.",
  },
  {
    id: 47,
    text: "He and his friends were traveling by a speedy train when his friend looked out of the window and got hit by a pole. He...",
    response:
      "Immediately attend to his injured friend, alert the train staff about the incident, and ensure necessary medical assistance is provided as quickly as possible.",
  },
  {
    id: 48,
    text: "His friend cracked jokes on his girlfriend, and his girlfriend got offended. He...",
    response:
      "Mediate the situation, address the concerns of both parties, and encourage open and respectful communication to resolve any misunderstandings and restore harmony.",
  },
  {
    id: 49,
    text: "He returns from a picnic and comes to know that his mother has been insulted by his neighbor's son. He would...",
    response:
      "Support his mother emotionally, communicate with the neighbor's son's family to understand the situation, and seek a peaceful resolution while maintaining dignity and respect.",
  },
  {
    id: 50,
    text: "She has received a love letter from an unknown person. She would...",
    response:
      "Exercise caution, consider the intentions and authenticity of the letter, consult with trusted individuals for advice, and take appropriate steps to ensure personal safety and well-being.",
  },
  {
    id: 51,
    text: "He was out on the field posting. His wife complained to him about not getting a good response from his parents. He...",
    response:
      "Listen empathetically to his wife's concerns, offer support and understanding, and communicate with his parents to address the issue and find a way to improve the relationship.",
  },
  {
    id: 52,
    text: "His father had a dispute with his uncle over landed property. He...",
    response:
      "Encourage peaceful dialogue between his father and uncle, offer mediation if necessary, and seek legal assistance to resolve the property dispute through proper channels.",
  },
  {
    id: 53,
    text: "He went to college, but rowdy students told him to boycott the class. He...",
    response:
      "Assess the situation and make an informed decision based on personal values and academic goals, considering the potential consequences of boycotting the class.",
  },
  {
    id: 54,
    text: "He was appointed Langar Commander. The dal has often been having stones, which was complained about by the dining members. He...",

    response:
      "Investigate the issue, ensure quality control measures are in place, take necessary steps to improve the quality of dal, and address the concerns of the dining members.",
  },
  {
    id: 55,
    text: "He didn’t do well in the written test of SCO Commission. His friends advised him not to venture into the future. He...",
    response:
      "Evaluate his own aspirations and capabilities, seek constructive feedback to identify areas of improvement, and make an informed decision about pursuing future opportunities.",
  },
  {
    id: 56,
    text: "It was a desert area, and he lost his way, being away from the team members. He...",
    response:
      "Stay calm, retrace his steps, use navigational tools if available, and seek assistance from local authorities or fellow travelers to find his way back to the team.",
  },
  {
    id: 57,
    text: "He has lost his way in the jungle. He...",
    response:
      "Stay in one place to avoid getting further lost, use navigational tools or natural landmarks for orientation, and try to attract attention or find a trail that can lead him back to civilization.",
  },
  {
    id: 58,
    text: "You proposed to your girlfriend for marriage, and she rejected your proposal. You...",
    response:
      "Respect her decision, communicate openly to understand her reasons, and focus on personal growth and moving forward with grace and dignity.",
  },
  {
    id: 59,
    text: "She wants to continue her job, but her newly married husband is totally against it. She would...",
    response:
      "Engage in open and honest communication with her husband, share her reasons and aspirations, explore compromises or alternative solutions, and seek a mutually agreeable arrangement.",
  },
  {
    id: 60,
    text: "He was given the task to vigil over the ongoing maths Olympiad. He observed some candidates using unfair means in the exam. He...",
    response:
      "Follow the established protocols for exam invigilation, discreetly observe and document any instances of unfair means, and report the findings to the appropriate authorities for further investigation and action.",
  },
  {
    id: 60,
    text: "He was given the task to vigil over the ongoing maths Olympiad. He observed some candidates using unfair means in the exam. He...",
    response:
      "Follow the established protocols for exam invigilation, discreetly observe and document any instances of unfair means, and report the findings to the appropriate authorities for further investigation and action.",
  },
];

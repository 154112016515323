import React from "react";
import Footers from "../footer/Footers";
import Navbar from "../navbar/navbar";

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <div className="legal">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to our e-learning website (www.tropper.com). By accessing or
          using our Site, you agree to be bound by these terms and conditions
          (&quot;Terms&quot;). If you do not agree with these Terms, please do
          not use our Site.
        </p>

        <p>
          We reserve the right to update these Terms at any time, and we
          encourage you to review the Terms periodically. Any changes will be
          effective immediately upon posting to the Site. Your continued use of
          the Site following the posting of any changes will constitute your
          acceptance of such changes.
        </p>

        <p className="legal-point">Use of the Site:-</p>

        <p>
          The content on our Site, including text, graphics, images, and other
          materials, is for informational purposes only and is not intended to
          be a substitute for professional advice, such as legal or financial
          advice. You should seek the advice of a qualified professional before
          relying on any information on our Site.
        </p>

        <p>
          You may not use our Site for any illegal or unauthorized purpose, or
          in any way that could damage, disable, overburden, or impair the Site
          or interfere with any other party's use and enjoyment of the Site. You
          may not attempt to gain unauthorized access to any portion of the Site
          or any other systems or networks connected to the Site.
        </p>

        <p className="legal-point">Refund Policy:-</p>

        <p>
          All purchases on our Site are final and non-refundable. This includes
          course purchases, course bundles, and special offers. We encourage you
          to carefully review the course materials and descriptions before
          making a purchase to ensure that the course meets your needs and
          expectations.
        </p>

        <p>
          If you have any technical issues accessing the course or any other
          concerns about the quality of the course, please contact us via email
          at troopers.contact@gmail.com and we will do our best to resolve the
          issue. However, we are unable to offer refunds in these cases.
        </p>

        <p className="legal-point"> Intellectual Property:-</p>

        <p>
          The content on our Site, including text, graphics, images, and other
          materials, is protected by copyright and other intellectual property
          laws. You may not use any content on our Site for commercial purposes
          without obtaining a license to do so from us or our licensors.
        </p>

        <p className="legal-point">Privacy:-</p>

        <p>
          We respect your privacy and are committed to protecting it. Please
          review our privacy policy, which explains how we collect, use, and
          share information about you.
        </p>

        <p className="legal-point">Limitation of Liability:-</p>

        <p>
          We will not be liable for any damages of any kind arising from the use
          of our Site, including but not limited to direct, indirect,
          incidental, punitive, and consequential damages.
        </p>

        <p className="legal-point">Governing Law:-</p>

        <p>
          These Terms and your use of our Site are governed by and construed in
          accordance with the laws of India, without giving effect to any
          principles of conflicts of law. Any legal action or proceeding arising
          out of or related to these Terms or the Site will be brought
          exclusively in the courts located in India.
        </p>

        <p className="legal-point">Severability:-</p>

        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable, that provision will be enforced to the maximum extent
          possible, and the remaining provisions of these Terms will remain in
          full force and effect.
        </p>

        <p className="legal-point">Contact Us</p>

        <p>
          If you have any questions about our refund policy or need assistance
          with a technical issue, please contact us at
          troppers.contact@gmail.com or Message us - www.troppers.com/contact.
          We are always happy to help.
        </p>
        <p>
          <strong>Email</strong> - troppers.contact@gmail.com
        </p>
        <p>
          <strong>Address</strong> - Kadirabaar, Darbhanga, Bihar, PIN - 847104
        </p>
        <p>
          <strong>Mobile Number </strong>- +91 7061074347
        </p>
      </div>
      <Footers />
    </>
  );
};

export default TermsAndConditions;

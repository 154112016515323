import React from "react";
import {
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import * as FaIcons from "react-icons/fa";
import * as SiIcons from "react-icons/si";
import * as IoIcons from "react-icons/io";
import "./socialShare.css";

export const SocialShare = () => {
  return (
    <div className="social-share">
      <div className="social-child">
        {" "}
        <TwitterShareButton
          url={window.location.href}
          title="Checkout This Awesome Website For Service Selection Board(SSB) Preparation."
        >
          <FaIcons.FaTwitterSquare size={28} style={{ color: "#00acee" }} />
        </TwitterShareButton>
      </div>
      <div className="social-child">
        {" "}
        <FacebookShareButton
          url={window.location.href}
          title="Checkout This Awesome Website For Service Selection Board(SSB) Preparation."
        >
          <FaIcons.FaFacebookSquare size={28} style={{ color: "#3b5998" }} />
        </FacebookShareButton>
      </div>
      <div className="social-child">
        <TelegramShareButton
          url={window.location.href}
          title="Checkout This Awesome Website For Service Selection Board(SSB) Preparation."
        >
          <SiIcons.SiTelegram size={28} style={{ color: "#0088cc" }} />
        </TelegramShareButton>
      </div>
      <div className="social-child">
        <WhatsappShareButton
          url={window.location.href}
          title="Checkout This Awesome Website For Service Selection Board(SSB) Preparation."
        >
          <IoIcons.IoLogoWhatsapp size={28} style={{ color: "#25D366" }} />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export const situationsSix = [
  {
    id: 1,
    text: "Government Hospital in your area is taking money illegally from the people to give vaccine doses. You...",
    response:
      "Report the illegal activity to the higher authorities, provide evidence if possible, and urge them to take action to stop the corruption.",
  },
  {
    id: 2,
    text: "She wants to marry but her choice is different from her parents choice. She would...",
    response:
      "Have an honest conversation with her parents to try and understand their concerns, and Convince them.",
  },
  {
    id: 3,
    text: "He was ironing his new pants when suddenly he received an electric shock and noticed the wire burning. He...",
    response:
      "Quickly turned off the power source, unplugged the iron, and sought medical attention if necessary.",
  },
  {
    id: 4,
    text: "He was travelling in a car which unexpectedly broke down on the way. It was getting dark and no help was in sight. He would...",
    response:
      "Try to identify and fix the problem if possible, call for roadside assistance or a mechanic, and ensure his own safety while waiting for help to arrive.",
  },
  {
    id: 5,
    text: "If in a group, his opinion differs from that of other members. He...",
    response:
      "Express his opinion respectfully and try to find common ground with the other members. If consensus cannot be reached, accept the decision of the group and move forward.",
  },
  {
    id: 6,
    text: "His uncle wants him to study science and then do management course but his father wants him to do MA. His uncle is more educated. He would...",
    response:
      "Consider the opinions of both his uncle and father, but ultimately make a decision based on his own interests, passions, and career goals.",
  },
  {
    id: 7,
    text: "A reputed football team wants him to play for them in May but his college is playing inter-university tournament at that time. He...",
    response:
      "Prioritize his commitment to his college team and decline the offer from the football team, but express gratitude for the opportunity and keep the door open for future opportunities.",
  },
  {
    id: 8,
    text: "A railway bridge next to his village catches fire and the train is to arrive any time. He...",
    response:
      "Immediately inform the authorities and evacuate the area to ensure the safety of the residents and the arriving train.",
  },
  {
    id: 9,
    text: "He finds an electric transformer has caught fire in front of his house. He...",
    response:
      "Call the fire department immediately, evacuate any individuals in the vicinity to a safe location, and take measures to prevent the fire from spreading.",
  },
  {
    id: 10,
    text: "During the city riot few men of his street come to burn his house. He...",
    response:
      "Contact the authorities immediately and evacuate the house if possible.",
  },
  {
    id: 11,
    text: "He heard the shouts for help and found a small boy being kidnapped. He...",
    response:
      "Call the police immediately, provide a detailed description of the kidnapper and help the boy if possible.",
  },
  {
    id: 12,
    text: "He found that he was being given step motherly treatment by his seniors. He...",
    response:
      "Address the issue with the seniors, document the treatment, and escalate to higher authorities if necessary.",
  },
  {
    id: 13,
    text: "While on night duty his colleague fainted. He...",
    response:
      "Provided immediate medical assistance and informed the supervisor.",
  },
  {
    id: 14,
    text: "After his marriage his in-laws were forcing him to leave the service. He...",
    response:
      "Tell them about his commitment to the service and change their mind.",
  },
  {
    id: 15,
    text: "Village patwari wanted some bribe for doing a land work for his village. He...",
    response:
      "Refuse to pay the bribe, report the incident to the higher authorities.",
  },
  {
    id: 16,
    text: "Mother wants her to prepare the breakfast and also wants to cook dinner but her examination is nearing. She...",
    response:
      "Communicate with her mother, explain the situation and divide the tasks accordingly.",
  },
  {
    id: 17,
    text: "Some people are abusing his old mother. He...",
    response:
      "Intervene immediately to stop the abuse and ensure his mother's safety.",
  },
  {
    id: 18,
    text: "He and his friends are standing in doorway of train. As the train starts, a friend falls off. He...",
    response:
      "Pull the emergency chain and seek immediate medical assistance for the fallen friend.",
  },
  {
    id: 19,
    text: "You fall seriously ill just before final exam. You...",
    response:
      "Take proper medication and rest and give examination to the best of my ability.",
  },
  {
    id: 20,
    text: "A speeding motor truck ran over a man as he happened to pass by. He...",
    response: "Immediately call an ambulance or police and provide first aid.",
  },
  {
    id: 21,
    text: " He is going to sign a contract, suddenly he got news that one of his friend who had helped him once, met an accident and is in ICU. He...",
    response:
      "Prioritize friend's emergency, inform parties involved, request postponement of contract signing.",
  },
  {
    id: 22,
    text: "He is getting late for his important meeting. He...",
    response:
      "Prioritize his tasks, plan the route, and leave as soon as possible for the meeting.",
  },
  {
    id: 23,
    text: "During a trekking trip, he was left alone in the jungle. He...",
    response:
      "Remain calm, stay put, and try to signal for help and find a way out.",
  },
  {
    id: 24,
    text: "He had exams the next day & the road to his school was flooded with continuous rain for last two days...",
    response:
      "Plan an alternate route or transportation to reach the school safely.",
  },
  {
    id: 25,
    text: "She has secured very poor marks in B.Sc. part one, her parents are annoyed. She would...",
    response:
      "Take responsibility for the poor performance, make a plan to improve, and work hard and assure parent to do better next time.",
  },
  {
    id: 26,
    text: "His parents often irritated him with their orthodox ideas about the role of women in a society. He...",
    response:
      "Respectfully express disagreement and try to educate them on modern values of gender equality.",
  },
  {
    id: 27,
    text: "He had already decided to vote for a particular candidate whereas his friends wanted his commitment for the other candidate. He...",
    response:
      "Respectfully maintain his own decision and encourage his friends to vote according to their own conscience.",
  },
  {
    id: 28,
    text: "His father had borrowed some money for his higher studies which he could not pay back. So He...",
    response:
      "Take responsibility to repay the debt, and work hard to earn and save money.",
  },
  {
    id: 29,
    text: "Demand of a loan from his close relative was urgent whereas he needed the same money for his son’s hostel admission. He...",
    response:
      "Explained the urgency of the situation and requested a flexible repayment plan.",
  },
  {
    id: 30,
    text: "Two of his best friends had quarreled with each other. He...",
    response:
      "Acted as mediator, facilitated reconciliation between the friends.",
  },
  {
    id: 31,
    text: "He had gone to cinema with his friend and he found students chasing a young girl. He...",
    response: "Intervened, stopped the students and ensured the girl's safety.",
  },
  {
    id: 32,
    text: "He noticed that two persons are fighting with each other at a restaurant where he had gone for dinner with his girlfriend. He...",
    response:
      "Called the police, provided details, ensured own and girlfriend's safety.",
  },
  {
    id: 33,
    text: "A young college student who had quarreled with his father had came to stay with him in the hostel. He...",
    response:
      "Encouraged the student to reconcile with his father, provided support.",
  },
  {
    id: 34,
    text: "His younger brother is an alcoholic and he often goes to take illicit country liquor. He...",
    response:
      "Intervene, counsel and support his younger brother to quit alcoholism.",
  },
  {
    id: 35,
    text: "His younger brother is a mechanical engineer from a regional college. He has been searching a job for the last three years but has not succeeded. He...",
    response: "Help his younger brother to improve his job search strategies.",
  },
  {
    id: 36,
    text: "His colleagues went on strike against the principal of the school. He...",
    response:
      "Maintain discipline and encourage peaceful resolution of the issue.",
  },
  {
    id: 37,
    text: "He saw a man in the deserted bazaar smashed a jeweller’s window, steal some ornaments and drive away in car. He...",
    response:
      "Call the police and report the crime with a detailed description.",
  },
  {
    id: 38,
    text: "He was caught alone at night by dacoits who demanded all valuables on him while he was unarmed. He...",
    response:
      "Stay calm, comply with the dacoits' demands, and report the incident to the police.",
  },
  {
    id: 39,
    text: "Her step mother does not like her and even wants her to perform all the house hold works. In case of refusal or delay she complains her father. She...",
    response:
      "Communicate with her father and step-mother to find a mutually agreeable solution.",
  },
  {
    id: 40,
    text: "He along with his friends was sitting in his college canteen. A few boys from other college came and started abusing him. He...",
    response:
      "Stay composed and seek help from college authorities or police if necessary.",
  },
  {
    id: 41,
    text: "In a drought affected area, the relief is coming but not reaching the affected persons. He is a social worker. He would...",
    response:
      "Coordinate with local authorities and volunteers to ensure relief reaches affected persons.",
  },
  {
    id: 42,
    text: "His peon is in need of money for his daughter’s marriage taking place after a week, he has some money to clear his dues within the next 20 days. He would...",
    response:
      "Provide the peon with a loan or advance to help him meet his financial needs or organize fund raising.",
  },
  {
    id: 43,
    text: "While delivering a lecture, he finds the audience is not showing any interest. He...",
    response:
      "Engage with the audience, make the lecture interactive and interesting.",
  },
  {
    id: 44,
    text: "There is a murder in his village and the opposition party has given his name to the police. He...",
    response: "Prove innocence with evidence.",
  },
  {
    id: 45,
    text: "He wanted to organize a cricket match of his college but his opponent has approached the authorities for hockey match. He would...",
    response: "Negotiate with authorities for cricket match.",
  },
  {
    id: 46,
    text: "He has occupied a particular seat in the unreserved compartment of a train for his journey. After a while personal come and asks him to vacate that seat because he was sitting on it earlier. He...",
    response:
      "Show the ticket and refuse to vacate the seat, call Police if necessary.",
  },
  {
    id: 47,
    text: "His neighbor called upon him one winter night for help in the absence of his wife as his daughter was very ill. He...",
    response: "Offer help and take the girl to a hospital.",
  },
  {
    id: 48,
    text: "He went on a tour with his friends for a coastal area, where suddenly a cyclic storm came and the situation became grave. He...",
    response: "Follow safety instructions and seek shelter.",
  },
  {
    id: 49,
    text: "In the absence of her father her step mother usually abuses her and even compels her father to abuse her. She...",
    response: "Reported the abuse to authorities and seeked help.",
  },
  {
    id: 50,
    text: "He was at the railway station where he asked someone about the time politely. The later abused him. He...",
    response: "Ignored and avoided the person.",
  },
  {
    id: 51,
    text: "You come to know that your once good friend is involved in some illigal activities. You...",
    response: "Confronted and advised the friend to stop.",
  },
  {
    id: 52,
    text: "Police refuses to register FIR for your stolen bike. What would you do...",
    response:
      "File a complaint with higher authorities and register complain aginst the police.",
  },
  {
    id: 53,
    text: "He was going steady with his girlfriend. One day she insisted for physical relationship. He...",
    response: "Respectfully decline and communicate boundaries.",
  },
  {
    id: 54,
    text: "He was travelling in train. It was stopped by the naxalites at a lonely place. There was no sign of police around. He...",
    response:
      "Contact nearest satation and report the siruation and seek help and meanwhile Follow instructions of the naxalites and remain calm.",
  },
  {
    id: 55,
    text: "His trusted friend had been held by the police on suspicion. He went to the police station but the SHO refused to listen to him. He...",
    response: "Try to gather evidence and seek legal assistance.",
  },
  {
    id: 56,
    text: "Her younger brother who is only two year younger always fights with her. She would...",
    response:
      "Try to understand the reason behind the fights and communicate calmly.",
  },
  {
    id: 57,
    text: "In one of the briefings, he realised that the plan of action spelt out by his seniors was full of flaws. He...",
    response: "Discuss the flaws with seniors and suggest alternatives.",
  },
  {
    id: 58,
    text: "After his graduation he was required to look after his family business and he had no experience. He...",
    response:
      "Seek guidance from experienced family members or hire a business consultant.",
  },
  {
    id: 59,
    text: "His mother hit the maid servant hard for breaking a costly decoration piece. He...",
    response: "Condemn the action of mother and make her apologise the maid.",
  },
  {
    id: 60,
    text: "He and his family members were travelling by a train which met with an accident at the dead of night. He...",
    response: "Follow emergency protocols and assist injured passengers.",
  },
];

export const wordNine = [
  {
    id: 1,
    text: "Food",
    response: "Food nourishes body.",
  },
  {
    id: 2,
    text: "Men",
    response: "Men are moral beings capable of reason.",
  },
  {
    id: 3,
    text: "Time Management",
    response: "Efficient Time Management leads to a productive life.",
  },
  {
    id: 4,
    text: "Networking",
    response: "",
  },
  {
    id: 5,
    text: "Vivacious",
    response: "",
  },
  {
    id: 6,
    text: "Diplomatic",
    response: "",
  },
  {
    id: 7,
    text: "Valiant",
    response: "",
  },
  {
    id: 8,
    text: "Sportsmanship",
    response: "",
  },
  {
    id: 9,
    text: "Self-esteem",
    response: "",
  },
  {
    id: 10,
    text: "Philanthrophy",
    response: "",
  },
  {
    id: 11,
    text: "Nazi",
    response: "",
  },
  {
    id: 12,
    text: "Edict",
    response: "",
  },
  {
    id: 13,
    text: "Seductive",
    response: "",
  },
  {
    id: 14,
    text: "Javelin",
    response: "",
  },
  {
    id: 15,
    text: "Gem",
    response: "",
  },
  {
    id: 16,
    text: "Arena",
    response: "",
  },
  {
    id: 17,
    text: "Clinic",
    response: "",
  },
  {
    id: 18,
    text: "Knot",
    response: "",
  },
  {
    id: 19,
    text: "Mysterious",
    response: "",
  },
  {
    id: 20,
    text: "Cloud",
    response: "",
  },
  {
    id: 21,
    text: "Burn",
    response: "",
  },
  {
    id: 22,
    text: "Martial",
    response: "",
  },
  {
    id: 23,
    text: "Variant",
    response: "",
  },
  {
    id: 24,
    text: "Obligation",
    response: "",
  },
  {
    id: 25,
    text: "Korea",
    response: "",
  },
  {
    id: 26,
    text: "Civilization",
    response: "",
  },
  {
    id: 27,
    text: "Cipher",
    response: "",
  },
  {
    id: 28,
    text: "Awful",
    response: "",
  },
  {
    id: 29,
    text: "Creepy",
    response: "",
  },
  {
    id: 30,
    text: "Agreeable",
    response: "",
  },
  {
    id: 31,
    text: "Self-Conscious",
    response: "",
  },
  {
    id: 32,
    text: "Homesick",
    response: "",
  },
  {
    id: 33,
    text: "Headmaster",
    response: "",
  },
  {
    id: 34,
    text: "Sobb",
    response: "",
  },
  {
    id: 35,
    text: "Exhibition",
    response: "",
  },
  {
    id: 36,
    text: "Ruthless",
    response: "",
  },
  {
    id: 37,
    text: "Noxious",
    response: "",
  },
  {
    id: 38,
    text: "Endorse",
    response: "",
  },
  {
    id: 39,
    text: "Compound",
    response: "",
  },
  {
    id: 40,
    text: "Angelic",
    response: "",
  },
  {
    id: 41,
    text: "Embrace",
    response: "",
  },
  {
    id: 42,
    text: "Everlasting",
    response: "",
  },
  {
    id: 43,
    text: "Year",
    response: "",
  },
  {
    id: 44,
    text: "Boil",
    response: "",
  },
  {
    id: 45,
    text: "Authorise",
    response: "",
  },
  {
    id: 46,
    text: "Reassess",
    response: "",
  },
  {
    id: 47,
    text: "Something",
    response: "",
  },
  {
    id: 48,
    text: "Readymade",
    response: "",
  },
  {
    id: 49,
    text: "Single",
    response: "",
  },
  {
    id: 50,
    text: "Evergreen",
    response: "",
  },
  {
    id: 51,
    text: "Extrovert",
    response: "",
  },
  {
    id: 52,
    text: "Ignorant",
    response: "",
  },
  {
    id: 53,
    text: "Nasty",
    response: "",
  },
  {
    id: 54,
    text: "Tormented",
    response: "",
  },
  {
    id: 55,
    text: "Unproductive",
    response: "",
  },
  {
    id: 56,
    text: "Allure",
    response: "",
  },
  {
    id: 57,
    text: "Aspire",
    response: "",
  },
  {
    id: 58,
    text: "Achiever",
    response: "",
  },
  {
    id: 59,
    text: "Dazzling",
    response: "",
  },
  {
    id: 60,
    text: "Entice",
    response: "",
  },
];

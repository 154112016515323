export const quotes = [
  "The future belongs to those who believe in the beauty of their dreams.",
  "Success is not final, failure is not fatal: It is the courage to continue that counts.",
  "Discipline is the bridge between goals and accomplishments.",
  "The harder you work, the luckier you get.",
  "No goal is too high if we climb with care and confidence.",
  "You don't have to be great to start, but you have to start to be great.",
  "Success is not just about making it, but also about becoming a better person.",
  "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
  "Obstacles are those frightful things you see when you take your eyes off the goal.",
  "In the middle of difficulty lies opportunity.",
  "Success is not in what you have, but who you are.",
  "The secret to success is to know something nobody else knows.",
  "The only way to do great work is to love what you do.",
  "You never fail until you stop trying.",
  "Strength does not come from physical capacity. It comes from an indomitable will.",
  "Don't let what you cannot do interfere with what you can do.",
  "Success is the sum of small efforts repeated day in and day out.",
  "Failure is not falling down, but refusing to get up.",
  "The best way to predict the future is to create it.",
  "The difference between a successful person and others is not a lack of strength, not a lack of knowledge, but rather a lack in will.",
  "The more difficult the victory, the greater the happiness in winning.",
  "Don't count the days, make the days count.",
  "The mind is everything. What you think, you become.",
  "Success is not the key to happiness. Happiness is the key to success.",
  "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
  "The only limit to our realization of tomorrow will be our doubts of today.",
  "If you want to achieve greatness, stop asking for permission.",
  "Success is not overnight. It is when everyday you get a little better than the day before.",
  "You are never too old to set another goal or to dream a new dream.",
  "The only person you should try to be better than is the person you were yesterday.",
  "Your time is limited, don't waste it living someone else's life.",
  "The best revenge is massive success.",
  "The future belongs to those who believe in the beauty of their dreams.",
  "You miss 100% of the shots you don't take.",
  "It does not matter how slowly you go as long as you do not stop.",
  "Don't watch the clock; do what it does. Keep going.",
  "Believe you can and you're halfway there.",
  "The biggest risk is not taking any risk.",
  "The best way to predict your future is to create it.",
  "Don't wait for opportunity. Create it.",
  "Success is not the absence of failure; it's the persistence through failure.",
  "The secret of getting ahead is getting started.",
  "It does not matter how many times you get knocked down, but how many times you get up.",
  "The only limit to our realization of tomorrow will be our doubts of today.",
  "Great things never came from comfort zones.",
  "Dream big and dare to fail.",
  "The only way to do great work is to love what you do.",
  "If you want to achieve greatness, stop asking for permission.",
  "Success is not the key to happiness. Happiness is the key to success.",
  "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
  "The harder you work for something, the greater you'll feel when you achieve it.",
  "Don't let your dreams be dreams.",
  "In the end, we only regret the chances we didn't take.",
  "Your time is limited, don't waste it living someone else's life.",
  "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
  "If opportunity doesn't knock, build a door.",
  "Strive for progress, not perfection.",
  "The best revenge is massive success.",
  "Success is not the key to happiness. Happiness is the key to success.",
  "The future belongs to those who believe in the beauty of their dreams.",
  "You miss 100% of the shots you don't take.",
  "It does not matter how slowly you go as long as you do not stop.",
  "Don't watch the clock; do what it does. Keep going.",
  "Believe you can and you're halfway there.",
  "The biggest risk is not taking any risk.",
  "The best way to predict your future is to create it.",
  "Don't wait for opportunity. Create it.",
  "Success is not the absence of failure; it's the persistence through failure.",
  "The secret of getting ahead is getting started.",
  "It does not matter how many times you get knocked down, but how many times you get up.",
  "Great things never came from comfort zones.",
  "Dream big and dare to fail.",
  "The only way to do great work is to love what you do.",
  "If you want to achieve greatness, stop asking for permission.",
  "Success is not the key to happiness. Happiness is the key to success.",
  "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
  "The harder you work for something, the greater you'll feel when you achieve it.",
  "Don't let your dreams be dreams.",
  "In the end, we only regret the chances we didn't take.",
  "Your time is limited, don't waste it living someone else's life.",
  "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
  "If opportunity doesn't knock, build a door.",
  "Strive for progress, not perfection.",
];

import React from "react";
import Footer from "rc-footer";
import "rc-footer/assets/index.css";
import * as Bsicon from "react-icons/bs";
import * as Aiicon from "react-icons/ai";
import * as Mdicon from "react-icons/md";

const Footers = () => {
  return (
    <Footer
      columns={[
        {
          title: "Links",

          items: [
            {
              title: "OIR",
              url: "https://www.troppers.com/service-selection-board-ssb/officer-intelligence-rating-test-oir",
            },
            {
              title: "PPDT",
              url: "https://www.troppers.com/service-selection-board-ssb/picture-perception-and-descriptive-test-ppdt",
            },
            {
              title: "TAT",
              url: "https://www.troppers.com/service-selection-board-ssb/thematic-apperception-test-tat",
            },
            {
              title: "WAT",
              url: "https://www.troppers.com/service-selection-board-ssb/word-association-test-wat",
            },
            {
              title: "SRT",
              url: "https://www.troppers.com/service-selection-board-ssb/situation-reaction-test-srt",
            },
            {
              title: "GPE",
              url: "https://www.troppers.com/service-selection-board-ssb/group-planning-exercise-gpe",
            },
            {
              title: "SDT",
              url: "https://www.troppers.com/self-description-test-sdt/instruction",
            },
            {
              title: "Personal Interview(PI)",
              url: "https://www.troppers.com/service-selection-board-ssb/personal-interview-pi-questions-and-answers",
            },
          ],
        },
        {
          title: "Legal",
          openExternal: true,
          items: [
            {
              title: "Terms and Conditions",
              url: "https://www.troppers.com/terms-and-conditions",

              icon: <Bsicon.BsBook />,
            },
            {
              title: "Privacy Policy",
              url: "https://www.troppers.com/privacy-policy",

              icon: <Mdicon.MdOutlinePrivacyTip />,
            },
          ],
        },
        {
          title: "Social",

          items: [
            {
              title: "Instagram",
              url: "https://www.instagram.com/troppers_ssb",
              openExternal: true,
              icon: <Bsicon.BsInstagram />,
            },
            {
              title: "Email",
              url: "mailto:troppers.contact@gmail.com",
              openExternal: true,
              icon: <Aiicon.AiOutlineMail />,
            },
          ],
        },
      ]}
      bottom="Made with ❤️ by Troppers Team. Troppers&copy;2023"
      backgroundColor="#0F2449"
    />
  );
};

export default Footers;

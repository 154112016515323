import { questionsOne } from "./questionsT1";
import { questionsTwo } from "./questionsT2";
import { questionsThree } from "./questionsT3";
import { questionsFour } from "./questionsT4";
import { questionsFive } from "./questionsT5";

export const questions = {
  one: questionsOne,
  two: questionsTwo,
  three: questionsThree,
  four: questionsFour,
  five: questionsFive,
};

export const situationsTen = [
  {
    id: 1,
    text: "You are watching a movie in a cinema hall with your girlfriend when you suddenly see a bomb planted next to your seat. You will...",
    response:
      "Immediately alert authorities, evacuate the cinema, and ensure the safety of others.",
  },
  {
    id: 2,
    text: "You come home late from your office, and your mother is angry. You would...",
    response:
      "Apologize for being late, explain the reasons, and assure her of being more punctual.",
  },
  {
    id: 3,
    text: "There are lots of potholes in the road of your college campus. You...",
    response:
      "Report the issue to the concerned authorities for repair and improvement.",
  },
  {
    id: 4,
    text: "The drinking water in your area is polluted...",
    response:
      "Raise awareness about the issue, report it to the appropriate authorities, and seek solutions.",
  },
  {
    id: 5,
    text: "You have a lot of free time, and you are near a village school that has just one teacher. You will...",
    response:
      "Volunteer to support the school, assist the teacher, and provide educational help to the students.",
  },
  {
    id: 6,
    text: "His colony is organizing a festival fair. His action will be...",
    response:
      "Participate actively, contribute ideas, and assist in organizing the festival fair.",
  },
  {
    id: 7,
    text: "Your college principal asks for suggestions to help families of war victims. You will...",
    response:
      "Propose initiatives for financial, emotional, and educational support to war victim families.",
  },
  {
    id: 8,
    text: "You have been fired from your job stating the reason for your arrogance at the workplace. You will...",
    response:
      "Reflect on your behavior, learn from the experience, and seek self-improvement opportunities.",
  },
  {
    id: 9,
    text: "The girl you love tells you that their parents are seeing a bridegroom for her...",
    response:
      "Respect her decision and support her in pursuing her happiness and personal choices.",
  },
  {
    id: 10,
    text: "No one but you witnessed a murder, and the person responsible threatened you with your life if you disclosed. You will...",
    response:
      "Seek protection from the authorities, provide them with information, and cooperate in the investigation.",
  },
  {
    id: 11,
    text: "You came to know of some theft in your department by one of your co-workers...",
    response:
      "Gather evidence discreetly, report the theft to the appropriate authorities, and maintain confidentiality.",
  },
  {
    id: 12,
    text: "A friend of yours calls you at midnight and asks you to meet him straight away...",
    response:
      "Assess the urgency and importance of the situation before deciding whether to meet or discuss later.",
  },
  {
    id: 13,
    text: "Your father asks for a suggestion for the adoption of a pet. You will...",
    response:
      "Consider the family's preferences and lifestyle, research suitable pets, and provide recommendations.",
  },
  {
    id: 14,
    text: "Your best friend wants to marry a boy/girl who has spent one year in jail. You will...",
    response:
      "Have an open and honest conversation with your friend, express concerns, and provide support.",
  },
  {
    id: 15,
    text: "He is burned out from his job. What will he do to overcome it...",
    response:
      "Take a break, seek support from colleagues or professionals, and engage in self-care activities.",
  },
  {
    id: 16,
    text: "You are selected to give an opening speech for the annual function of your college, but just before your performance, you feel very nervous. You will...",
    response:
      "Practice deep breathing, positive self-talk, and focus on delivering the speech to the best of your abilities.",
  },
  {
    id: 17,
    text: "He often feels angry and irritated with small mistakes. He will...",
    response:
      "Identify triggers, practice anger management techniques, and seek professional help if needed.",
  },
  {
    id: 18,
    text: "The best decision you made in your life is...",
    response:
      "Reflect on personal experiences, consider achievements and personal growth, and identify the decision that had the most positive impact.",
  },
  {
    id: 19,
    text: "You are attending a political rally when a person starts firing in the air. You will...",
    response:
      "Stay calm, seek safety, and follow instructions from security personnel or law enforcement officers.",
  },
  {
    id: 20,
    text: "Your mother comes to know about your girlfriend/boyfriend, and she is not happy about it. You will...",
    response:
      "Have an open and respectful conversation with your mother, understand her concerns, and try to find common ground.",
  },
  {
    id: 21,
    text: "What if someone abuses your sister in front of you because of your mistake...",
    response:
      "Apologize for the mistake, intervene to protect your sister, and address the situation calmly but firmly.",
  },
  {
    id: 22,
    text: "He is working in his office when a sudden earthquake occurs. He will...",
    response:
      "Follow the designated safety procedures, seek shelter under sturdy furniture, and assist others if possible.",
  },
  {
    id: 23,
    text: "He is having a conversation with his colleague when his boss starts shouting at him. He will...",
    response:
      "Remain calm, listen attentively, and address the situation professionally and respectfully.",
  },
  {
    id: 24,
    text: "You are in enemy territory conducting an operation when one of your men informs you that your team has been surrounded by the enemy. You will...",
    response:
      "Assess the situation, formulate a plan to evade or engage the enemy, and prioritize the safety of your team.",
  },
  {
    id: 25,
    text: "What steps would you like to suggest to improve the lives of farmers in this country...",
    response:
      "Promote agricultural education, provide financial assistance, and ensure access to advanced farming techniques and markets.",
  },
  {
    id: 26,
    text: "When you feel demotivated about your goal, you...",
    response:
      "Revisit your purpose, break down the goal into smaller tasks, seek support and motivation from others.",
  },
  {
    id: 27,
    text: "He is a newly appointed professor in a university, and a girl accuses him of harassment. He will...",
    response:
      "Take the accusation seriously, cooperate with the investigation, and maintain professionalism throughout the process.",
  },
  {
    id: 28,
    text: "You feel that your friend circle is not trustworthy. You will...",
    response:
      "Reevaluate the trustworthiness of individuals, seek new friendships, and focus on building relationships based on trust and mutual respect.",
  },
  {
    id: 29,
    text: "When going to the market, he finds an injured dog lying on the roadside. He will...",
    response:
      "Contact animal welfare organizations, provide immediate medical attention, and facilitate the dog's rescue and rehabilitation.",
  },
  {
    id: 30,
    text: "She is the only girl in the office, and she feels that she is not getting cooperation from her colleagues. She...",
    response:
      "Communicate her concerns with colleagues and superiors, promote gender equality, and seek support from HR if necessary.",
  },
  {
    id: 31,
    text: "Your subordinates often ignore your orders. What action will you take...",
    response:
      "Reevaluate your communication style, provide clear instructions, and address any underlying issues.",
  },
  {
    id: 32,
    text: "You are visiting the Taj Mahal and found a person selling something very overpriced to a foreigner. You will...",
    response:
      "Inform the authorities or tourist guides about the situation to protect the visitor.",
  },
  {
    id: 33,
    text: "Your younger sister feels irritated by your bossy nature in the house. You will...",
    response:
      "Reflect on your behavior, be more considerate, and find a balance between authority and understanding.",
  },
  {
    id: 34,
    text: "What is one of the most important things in your daily routine...",
    response:
      "Prioritize self-care, such as exercise, meditation, or spending quality time with loved ones.",
  },
  {
    id: 35,
    text: "He feels that his pocket money should be increased, but his father refuses. He will...",
    response:
      "Have a calm and respectful conversation with his father, present his reasons, and negotiate a compromise.",
  },
  {
    id: 36,
    text: "You have a full day of appointments with many political leaders, and your friend faced a major accident and is in the ICU. You will...",
    response:
      "Prioritize the well-being of your friend, reschedule or delegate the appointments, and offer support to your friend and their family.",
  },
  {
    id: 37,
    text: "Which work do you find morally unethical and why...",
    response:
      "Identify activities that violate personal values, such as dishonesty or exploitation, and explain the reasons for considering them unethical.",
  },
  {
    id: 38,
    text: "A laborer working in the construction of your house dies in an accident. You will...",
    response:
      "Notify the authorities, provide assistance to the laborer's family, and ensure proper investigation of the accident.",
  },
  {
    id: 39,
    text: "You are crossing a red light with your mother when you find a very high-speed car is approaching you and is about to hit. You will...",
    response:
      "Immediately pull your mother to safety, alert others about the approaching danger, and report the incident to the appropriate authorities.",
  },
  {
    id: 40,
    text: "He came to know that his best friend is suffering from cancer and has lost all hope of getting better, and they are also running out of money for treatment. He will...",
    response:
      "Offer emotional support, help explore treatment options, and assist in finding financial resources or organizing fundraisers.",
  },
  {
    id: 41,
    text: "He is working in a call center when an angry customer calls and starts abusing him. He will...",
    response:
      "Remain calm, listen actively, follow the company's guidelines for handling difficult customers, and escalate the matter if necessary.",
  },
  {
    id: 42,
    text: "His hostel inmate has a habit of stealing things. He will...",
    response:
      "Speak to the hostel authorities, encourage the inmate to seek help, and ensure the safety of personal belongings.",
  },
  {
    id: 43,
    text: "Your brother got falsely trapped in a drug scam. How will you prove the charges wrong...",
    response:
      "Gather evidence, consult a lawyer, involve the appropriate authorities, and fight for justice through legal channels.",
  },
  {
    id: 44,
    text: "He forgot to switch off the burner, and the kitchen soon caught heavy fire. He...",
    response:
      "Immediately evacuate everyone, call the fire department, use fire extinguishers if safe, and prioritize everyone's safety.",
  },
  {
    id: 45,
    text: "His class teacher met with a serious accident on the school staircase while he was climbing the stairs at the same time. He immediately...",
    response:
      "Call for help, inform school authorities, provide assistance to the injured teacher, and follow instructions from emergency responders.",
  },
  {
    id: 46,
    text: "A young girl got mobbed on the road while you were the only eyewitness...",
    response:
      "Intervene to protect the girl, call the police, provide a detailed statement as a witness, and offer support to the victim.",
  },
  {
    id: 47,
    text: "While enjoying a pizza treat in Dominos, he looked outside at the beggar asking for a penny...",
    response:
      "Show compassion, offer food or assistance to the beggar, and contribute to organizations that help people in need.",
  },
  {
    id: 48,
    text: "Your maid has run away with your most precious family ring. Her address is unknown...",
    response:
      "Report the theft to the police, gather any evidence or information, and take steps to secure the house and belongings.",
  },
  {
    id: 49,
    text: "The city was all colored in different shades on Holi, but the street dog was searching for shelter...",
    response:
      "Provide shelter, food, or water to the street dog, or contact animal welfare organizations for assistance.",
  },
  {
    id: 50,
    text: "Her friends wanted to borrow her scooty for immediate purposes, but she has to go for her exam early in the morning...",
    response:
      "Explain the situation to her friends, suggest alternative solutions, and prioritize her academic commitments.",
  },
  {
    id: 51,
    text: "He was taking time for a proper parking space, but the crowd at the back started shouting and getting aggressive. He will...",
    response:
      "Stay calm, complete parking safely, and if necessary, seek assistance from traffic authorities or security personnel.",
  },
  {
    id: 52,
    text: "You have to take your mother for grocery shopping and also do your homework. You will...",
    response:
      "Plan and manage time effectively, prioritize tasks, and consider delegating or seeking assistance if feasible.",
  },
  {
    id: 53,
    text: "You were going to the market, and a mad dog started chasing you. You will...",
    response:
      "Stay calm, avoid sudden movements, seek a safe place, and contact animal control or authorities for help.",
  },
  {
    id: 54,
    text: "You booked a date from an online dating site and decided to meet for a coffee, but your date never showed up. You will...",
    response:
      "Stay positive, assess the situation, and consider reaching out to the date or moving on gracefully.",
  },
  {
    id: 55,
    text: "How will you overcome the fear of failure...",
    response:
      "Embrace failure as a learning opportunity, set realistic goals, seek support, and maintain a growth mindset.",
  },
  {
    id: 56,
    text: "When someone finds fault with your way of working, you will...",
    response:
      "Listen attentively, evaluate the feedback objectively, reflect on possible improvements, and take necessary action.",
  },
  {
    id: 57,
    text: "Whenever you have to take a new step, you...",
    response:
      "Prepare thoroughly, gather information, set clear goals, and take calculated risks.",
  },
  {
    id: 58,
    text: "Whenever you meet a stranger, you...",
    response:
      "Be polite, respectful, and cautious, maintaining personal boundaries and ensuring personal safety.",
  },
  {
    id: 59,
    text: "You never bother about other people's opinion about you because...",
    response:
      "Value self-acceptance, focus on personal growth, and prioritize opinions of trusted individuals who support you.",
  },
  {
    id: 60,
    text: "As compared to others, you feel that you are more...",
    response:
      "Recognize and appreciate your unique qualities, skills, or experiences without diminishing others.",
  },
];

export const situationsSix = [
  {
    id: 1,
    text: "His captain was injured before a crucial match, he was asked to lead the team. He...",
    response: "Accepted the responsibility and stepped up as the team leader.",
  },
  {
    id: 2,
    text: "He was wondering why most of the officers in India were corrupt and he...",
    response: "Decided to uphold integrity and set a positive example.",
  },
  {
    id: 3,
    text: "He was weak in studies, and knew that he could not do well in his education. He...",
    response:
      "Sought support and resources to improve his academic performance.",
  },
  {
    id: 4,
    text: "He was to appear for an exam and all of a sudden the curfew was imposed in that area. He...",
    response:
      "Seek assistance from the authorities and reach examination hall safely.",
  },
  {
    id: 5,
    text: "There is a person who sells smuggled goods. You too, like many others, have purchased a wristwatch from him. But it doesn't work. You...",
    response:
      "Reported the incident to the relevant authorities and sought a resolution.",
  },
  {
    id: 6,
    text: "In a discussion with your colleagues, you find yourself losing ground. You...",
    response:
      "Listened attentively, considered different perspectives, and sought opportunities for self-improvement.",
  },
  {
    id: 7,
    text: "You have been invited for dinner by one of your friends, and you are also required to attend Regimental 'Bara Khana'. You...",
    response:
      "Managed your time effectively to attend both events and maintain social connections.",
  },
  {
    id: 8,
    text: "While canvassing for a particular candidate in an election, you were threatened by opposition. You...",
    response:
      "Remained resilient, reported the threat to the authorities, and continued your campaign peacefully.",
  },
  {
    id: 9,
    text: "You were going for a walk and suddenly confronted by two goons (You are empty-handed)...",
    response:
      "Remained calm, assessed the situation, and sought help or found a way to ensure personal safety.",
  },
  {
    id: 10,
    text: "A VIP is about to visit your school on the same day you are going on a trip with your family, and you are asked by your principal to make all the arrangements. You...",
    response:
      "Communicated the scheduling conflict and coordinated with others to fulfill your responsibilities.",
  },
  {
    id: 11,
    text: "You were driving at high speed, and suddenly a person is run over by your car and he died. You...",
    response:
      "Stopped immediately, called emergency services, and cooperated with the authorities for investigation.",
  },
  {
    id: 12,
    text: "In his train compartment, two gunmen force passengers to give their belongings. He...",
    response:
      "Remained calm, followed the instructions, and ensured the safety of himself and others.",
  },
  {
    id: 13,
    text: "You are a team leader on a rock climbing expedition. One of your mates gets severely injured. You...",
    response:
      "Administered first aid, sought immediate medical help, and organized the necessary rescue or evacuation.",
  },
  {
    id: 14,
    text: "He cracked a joke on one of his friends, and his friend got angry. He...",
    response:
      "Apologized sincerely, acknowledged the impact of his words, and tried to make amends.",
  },
  {
    id: 15,
    text: "He was forced to join the Railways, but he was really not interested. He...",
    response:
      "Explored alternative career options and pursued his true passion.",
  },
  {
    id: 16,
    text: "He is studying for final year exam at night. He saw two masked persons entering the neighbor's house. He...",
    response:
      "Notified the authorities or neighborhood watch, prioritizing the safety of others.",
  },
  {
    id: 17,
    text: "Working under two commanding officers who are passing conflicting orders, what will you do...",
    response:
      "Sought clarification, communicated the situation to higher authorities, and followed the established chain of command.",
  },
  {
    id: 18,
    text: "In order to help the poor, he has a keen desire to have a good collection of money. What should he do...",
    response:
      "Explored ethical and legal ways to raise funds, such as fundraising or partnering with charitable organizations.",
  },
  {
    id: 19,
    text: "On opening the door of his bathroom in his room, he finds a big snake hanging from the ceiling. He...",
    response:
      "Stayed calm, left the area without disturbing the snake, and called a professional snake handler for assistance.",
  },
  {
    id: 20,
    text: "He was driving the bike without a helmet, and the traffic police caught him. He...",
    response:
      "Accepted the consequences, acknowledged the importance of road safety, and complied with the traffic regulations.",
  },
  {
    id: 21,
    text: "He has gone on a cycle expedition with six friends, but two cycles have got punctured on a deserted/isolated path. He will...",
    response:
      "Evaluated the situation, explored options for repair or assistance, and ensured the safety and well-being of the group.",
  },
  {
    id: 22,
    text: "After a late-night movie, your mother does not allow you to enter the house. You...",
    response:
      "Respected your mother's decision, communicated your situation, and found a temporary accommodation if necessary.",
  },
  {
    id: 23,
    text: "While he is waiting for a bus, an accident took place in front of him. He...",
    response:
      "Provided immediate assistance to the injured, called emergency services, and cooperated with authorities for investigation.",
  },
  {
    id: 24,
    text: "His friends came to borrow the book from which he was preparing for the next morning's paper. He...",
    response:
      "Prioritized his academic needs, explained the situation, and suggested alternative resources or study materials.",
  },
  {
    id: 25,
    text: "His plan had failed in the very beginning. He...",
    response:
      "Analyzed the reasons for the failure, learned from the experience, and adapted his approach for future endeavors.",
  },
  {
    id: 26,
    text: "While returning from the market, three persons with knives stopped him and asked him to hand over his valuable possessions. He...",
    response:
      "Remained calm, avoided confrontation, and handed over the possessions, prioritizing personal safety.",
  },
  {
    id: 27,
    text: "While on leave, he heard on the radio about the outbreak of WAR. He...",
    response:
      "Contacted his superiors, followed instructions, and reported for duty or sought guidance for the situation.",
  },
  {
    id: 28,
    text: "He is already late to complete his task. His colleagues refuse to cooperate with him. He...",
    response:
      "Communicated the urgency of the task, sought support from higher authorities, and explored ways to motivate and engage colleagues.",
  },
  {
    id: 29,
    text: "He works hard because...",
    response:
      "He is driven by his ambition, personal goals, and desire for self-improvement.",
  },
  {
    id: 30,
    text: "He receives an order from the CO to do certain work on the very day he is about to go home on leave. He...",
    response:
      "Respected the order, informed the CO about the leave plan, and requested guidance on prioritizing the tasks.",
  },
  {
    id: 31,
    text: "While preparing for exam, his father got ill and need medical attention. He...",
    response:
      "Sought medical help for his father while managing exam preparation.",
  },
  {
    id: 32,
    text: "His stepmother refused to give him money for bus fare. He...",
    response:
      "Explored alternative transportation options or borrowed money from a friend.",
  },
  {
    id: 33,
    text: "He is feeling sick and the next day he has to appear for his final exams. He...",
    response: "Rest and take necessary measures to recover before the exams.",
  },
  {
    id: 34,
    text: "He was new in the city and found all his money being stolen. He also lost his ATM. He...",
    response:
      "Reported the theft to the authorities and sought assistance for immediate needs.",
  },
  {
    id: 35,
    text: "He was attending driving classes and found his instructor casual and uninterested in teaching him. He...",
    response:
      "Communicated concerns to the driving school and requested a change of instructor.",
  },
  {
    id: 36,
    text: "While waiting for his train, a man started abusing him. He...",
    response:
      "Avoided confrontation and sought assistance from authorities or security personnel.",
  },
  {
    id: 37,
    text: "He found his new neighbors not compatible with him. He...",
    response:
      "Respected differences, maintained civility, and focused on personal well-being.",
  },
  {
    id: 38,
    text: "While returning home, he met with an accident with a car and the car driver blamed him for the accident. He...",
    response:
      "Contacted the authorities, collected evidence, and reported the incident for proper investigation.",
  },
  {
    id: 39,
    text: "During a discussion with his friends, he was not getting the chance to put up his point. He...",
    response:
      "Patiently waited for an opportunity to express his viewpoint or initiated a separate conversation later.",
  },
  {
    id: 40,
    text: "One of his friends fell into the lake, and he doesn't know swimming. He...",
    response:
      "Sought immediate help from others or called emergency services for the rescue.",
  },
  {
    id: 41,
    text: "He failed in the IAS exam in his last attempt. He...",
    response:
      "Reflected on the experience, sought guidance, and explored other career options.",
  },
  {
    id: 42,
    text: "He was on a trekking trip with his friends in Shimla when he discovered that one of his friends was missing. He...",
    response:
      "Initiated a search operation, contacted local authorities, and informed fellow trekkers.",
  },
  {
    id: 43,
    text: "He was assigned the task to organize a sports competition. Many preparations were pending before 2 days of the event. He...",
    response:
      "Prioritized tasks, delegated responsibilities, and worked efficiently to ensure successful event organization.",
  },
  {
    id: 44,
    text: "He was on a cycling expedition with his friends when he saw some miscreants with local weapons heading to an area. He...",
    response:
      "Notified the authorities or local law enforcement about the suspicious activity.",
  },
  {
    id: 45,
    text: "He has to appear in the exam next day when his friend asked him to lend him his books for preparation. He...",
    response:
      "Evaluated the urgency of his friend's need and provided support if feasible.",
  },
  {
    id: 46,
    text: "He was on the way to deliver a package to his sister. On the way, he realized that he doesn't have that package. He...",
    response:
      "Contacted his sister, apologized for the inconvenience, and arranged for alternative delivery.",
  },
  {
    id: 47,
    text: "TT came to check his ticket, but he found that his purse, containing the ticket and cash, was pickpocketed. He...",
    response:
      "Informed the TT about the situation and sought assistance in resolving the issue.",
  },
  {
    id: 48,
    text: "He has gone to the Taj Mahal and found beggars harassing a young foreigner for money. He...",
    response:
      "Intervened to protect the foreigner, ensured their safety, and reported the incident to the authorities.",
  },
  {
    id: 49,
    text: "While returning home after watching a movie at midnight, he found a man carrying a woman on his back on a lonely way. He...",
    response:
      "Assessed the situation, ensured the woman's safety, and contacted the appropriate authorities for assistance.",
  },
  {
    id: 50,
    text: "He was going on foot to catch a train at midnight. Suddenly, he sees a white van coming and throwing a person out. He...",
    response:
      "Alerted emergency services or nearby authorities about the incident for immediate help.",
  },
  {
    id: 51,
    text: "On his way to a job interview, he finds out that he forgot to bring his documents along. He didn't have enough time to pick his documents and reach the center. He...",
    response:
      "Contacted the interviewers to explain the situation and requested to reschedule the interview.",
  },
  {
    id: 52,
    text: "He was appointed Captain of the basketball team, but other players were against his appointment. He...",
    response:
      "Engaged in open communication, built team rapport, and worked to earn their respect and trust.",
  },
  {
    id: 53,
    text: "An epidemic has spread in a nearby village due to poor hygiene conditions. He...",
    response:
      "Raised awareness about hygiene practices, organized aid, and provided support to the affected villagers.",
  },
  {
    id: 54,
    text: "He was traveling in a train during the night. A thief came and stole his bag and jumped off the train. He...",
    response:
      "Reported the theft to the authorities, ensured personal safety, and made necessary arrangements.",
  },
  {
    id: 55,
    text: "At a lonely place, he found two men misbehaving with a girl. He...",
    response:
      "Intervened to protect the girl, called for help, and ensured her safety.",
  },
  {
    id: 56,
    text: "He wanted money for his education, and his close relations refused to help him financially anymore. He...",
    response:
      "Explored alternative funding sources, such as scholarships or part-time work, to support his education.",
  },
  {
    id: 57,
    text: "He found some people with hockey sticks surrounding a man. He...",
    response:
      "Alerted authorities or intervened to diffuse the situation and ensure everyone's safety.",
  },
  {
    id: 58,
    text: "The last train to his destination, where his presence was very important, was canceled due to bad weather. He...",
    response:
      "Explored alternative transportation options or sought accommodation until the weather improved.",
  },
  {
    id: 59,
    text: "While he was having coffee with his girlfriend in a restaurant, some boys came up and started beating him. He...",
    response:
      "Sought help from restaurant staff or nearby individuals, and contacted the authorities.",
  },
  {
    id: 60,
    text: "You are an officer posted at the border & suddenly shelling happens from the other side. You...",
    response:
      "Took necessary precautions, alerted nearby troops, and followed established protocols to ensure safety and security.",
  },
  {
    id: 60,
    text: "You are an officer posted at the border & suddenly shelling happens from the other side. You...",
    response:
      "Took necessary precautions, alerted nearby troops, and followed established protocols to ensure safety and security.",
  },
];

export const situationsNine = [
  {
    id: 1,
    text: "Your 15 years old younger brother is coming to the big city where you are staying, for the first time. You have gone to receive him on the railway station and have searched the whole train by which he was supposed to be arriving. He is not to be found. What would you do ?...",
    response:
      "Look for information or contact authorities to locate your brother.",
  },
  {
    id: 2,
    text: "You are studying in a college and staying in its hostel. Two other hostel friends suggest that you all should go to a movie. But there is no money. They plan to pinch away the timepiece from another hostler's room, sell it, and see the movie. What would you do ?...",
    response:
      "Discourage the plan and explore alternative ways to enjoy together.",
  },
  {
    id: 3,
    text: "To prepare well for a job interview, you would...",
    response:
      "Research the company, practice interview questions, and enhance relevant skills.",
  },
  {
    id: 4,
    text: "An Angel asks you three wishes and these will be fulfilled. What should you say...",
    response:
      "Consider your deepest desires and wishes, and respond accordingly.",
  },
  {
    id: 5,
    text: "Three important habits that you would like to bring in your lifestyle...",
    response:
      "Regular exercise, healthy eating, and consistent learning and growth.",
  },
  {
    id: 6,
    text: "He was required to go 10 kms more at midnight but his cycle got punctured. He...",
    response: "Repair the cycle or find an alternative mode of transportation.",
  },
  {
    id: 7,
    text: "While traveling by train he noticed that some civilian passengers started abusing the military persons in general. He...",
    response:
      "Maintain composure and avoid confrontation, unless necessary for safety.",
  },
  {
    id: 8,
    text: "He was going for his exam when he saw three men blindfolding an old woman. So he...",
    response: "Intervene to ensure the safety and well-being of the old woman.",
  },
  {
    id: 9,
    text: "There is a hailstorm in his village and the crops have not been harvested. So he...",
    response: "Take measures to protect the crops and minimize damage.",
  },
  {
    id: 10,
    text: "Labourers at your farmhouse refuse to cut the crops. Crops will be ruined if not cut before rain, which may come any time. He would...",
    response:
      "Engage in open communication, motivate the laborers, or seek alternative solutions.",
  },
  {
    id: 11,
    text: "You arrive home and find the whole family sitting in a surcharged and gloomy atmosphere. You...",
    response:
      "Inquire about the situation and provide support and comfort to family members.",
  },
  {
    id: 12,
    text: "You heard a cross talk on the telephone that there is a plot to murder the VIP visiting your town in the next half an hour. You would...",
    response:
      "Immediately inform the authorities or police about the potential threat.",
  },
  {
    id: 13,
    text: "You are out for a stroll in a rose garden and find a small and sweet child plucking the flower. You...",
    response:
      "Kindly explain the importance of preserving nature and discourage plucking.",
  },
  {
    id: 14,
    text: "You have been nominated president of the college annual athletic meet, but Raju, who is a good athlete, puts his claim on the presidentship and threatens you. So you...",
    response:
      "Engage in open dialogue, consider fair competition, and seek a resolution.",
  },
  {
    id: 15,
    text: "Suddenly you come to know that you are an adopted child. So you...",
    response:
      "Process the information, seek support, and address any emotional concerns.",
  },
  {
    id: 16,
    text: "There is a flood in your village and many houses have fallen down. You would...",
    response:
      "Ensure the safety of yourself and others, provide aid and support as possible.",
  },
  {
    id: 17,
    text: "He had to go a long way, but before even half of the distance was covered, he felt he could not walk further as his right shoe was pinching. So he...",
    response:
      "Address the shoe discomfort, such as adjusting or changing shoes if possible.",
  },
  {
    id: 18,
    text: "In a land dispute, his father was beaten up by the rival party. He...",
    response:
      "Ensure his father's safety, seek legal recourse, and involve authorities.",
  },
  {
    id: 19,
    text: "He was new to the college, and his classmates used to rag him. He...",
    response:
      "Reach out for support, report incidents to authorities, or confront the issue diplomatically.",
  },
  {
    id: 20,
    text: "The war was on. The NCC Cadets were digging trenches all over. But many more trenches were required. So he...",
    response:
      "Coordinate with fellow cadets, prioritize and organize trench digging efforts.",
  },
  {
    id: 21,
    text: "They planned to go for a movie but could not come to a common decision about the choice of the particular picture. He...",
    response:
      "Suggest alternatives or find a compromise to finalize the movie choice.",
  },
  {
    id: 22,
    text: "He was posted on the border area. When his post was shelled without any provocation. He...",
    response:
      "Follow appropriate protocols, take cover, and respond according to military guidelines.",
  },
  {
    id: 23,
    text: "There were floods in his district, and he was to collect funds. He...",
    response:
      "Organize relief efforts, reach out to individuals and organizations for donations.",
  },
  {
    id: 24,
    text: "The day he had to leave Delhi for Bangalore for urgent work, he developed a high fever. He had no reservation and the train was fully packed. He...",
    response:
      "Consult a doctor, explore alternative travel arrangements, or postpone the trip.",
  },
  {
    id: 25,
    text: "The bus overturned, and many people got injured. He escaped with some bruises. To send the injured to the hospital, he tried to stop the vehicle passing by but could not get any help. He...",
    response:
      "Seek immediate assistance from nearby establishments or contact emergency services.",
  },
  {
    id: 26,
    text: "He had gone to buy stamps from the post office, but he saw a lot of people already crowding at the window and quarreling with each other to get the 1st place. He...",
    response:
      "Exercise patience, wait for his turn, or consider alternative places to buy stamps.",
  },
  {
    id: 27,
    text: "One of his classmates had wrongly reported against him to the college principal for using abusive language. He...",
    response:
      "Provide his side of the story, present evidence if available, and defend his innocence.",
  },
  {
    id: 28,
    text: "He had landed in a place where he was a total stranger. He...",
    response:
      "Seek guidance,ask for directions, or interact with locals to navigate the new place.",
  },
  {
    id: 29,
    text: "His mother burst into tears just as he was about to leave for his unit. He...",
    response:
      "Comfort his mother, assure her well-being, and address any concerns before leaving.",
  },
  {
    id: 30,
    text: "He was becoming serious day by day. So he...",
    response:
      "Evaluate the reasons behind his seriousness and seek appropriate support or guidance.",
  },
  {
    id: 31,
    text: "He was sentry at the gate of his main office. Two well dressed young women came to him and requested that they be allowed in to see the CO for an urgent matter. He was ordered by the Adjutant not to allow anyone in. He...",
    response: "Follow orders and deny entry to the women as instructed.",
  },
  {
    id: 32,
    text: "In the night patrolling exercise, he suddenly fell in a blind and dry well which was 5 feet deep and he could not come out on his own. He...",
    response:
      "Seek help from colleagues or use available resources to escape the well.",
  },
  {
    id: 33,
    text: "The main water supply pipeline in his area has suddenly burst, and water was spreading fast near barracks. He...",
    response:
      "Notify authorities and take immediate action to control the water flow.",
  },
  {
    id: 34,
    text: "A Jawan of his unit reported that he found a bag containing currency notes of the value of Rs 50,000/- while on a night patrol duty. He...",
    response:
      "Secure the bag, inform superiors, and follow unit procedures for lost items.",
  },
  {
    id: 35,
    text: "You ran short of money whereas some essential items were yet to be purchased. So you...",
    response:
      "Prioritize the essential items and explore alternative solutions for the shortage.",
  },
  {
    id: 36,
    text: "One of the promotions in the unit was not based on the qualities of the individual. So you...",
    response:
      "Address the issue through appropriate channels, such as reporting to higher authorities.",
  },
  {
    id: 37,
    text: "You found that your subordinates are not happy with you. So you...",
    response:
      "Evaluate the reasons for their dissatisfaction and take necessary steps to address it.",
  },
  {
    id: 38,
    text: "Recently you have fallen into bad company. So you...",
    response:
      "Reassess your associations and distance yourself from negative influences.",
  },
  {
    id: 39,
    text: "Your partner became emotional after losing a few points in a debate competition. So you...",
    response:
      "Offer support and encouragement, emphasizing the value of participation and growth.",
  },
  {
    id: 40,
    text: "You have been asked to bribe your senior officer for promotion. So you...",
    response: "Refuse to engage in unethical practices and uphold integrity.",
  },
  {
    id: 41,
    text: "His father was returning victorious from the war, and he had gone to receive him at the Railway Station. As he was waiting, he heard a loud explosion. He...",
    response:
      "Investigate the source of the explosion and ensure the safety of those around.",
  },
  {
    id: 42,
    text: "You got beatings from your teacher. You...",
    response:
      "Report the incident to the appropriate authority or seek resolution through dialogue.",
  },
  {
    id: 43,
    text: "Your elder brother feels that money is everything in life. So you...",
    response:
      "Engage in a respectful discussion, presenting different perspectives and values.",
  },
  {
    id: 44,
    text: "He felt that the preparatory holidays were not enough for their final exam because he had fallen ill. He...",
    response:
      "Seek medical attention, communicate the situation to the relevant parties, and request necessary support.",
  },
  {
    id: 45,
    text: "One of your friends has started taking undue advantages from the teachers. You...",
    response:
      "Address the issue with your friend,highlighting the importance of fairness and ethical behavior.",
  },
  {
    id: 46,
    text: "One evening a person comes and requests for a night stay in your house. So you...",
    response:
      "Assess the situation, consider safety concerns, and make a decision accordingly.",
  },
  {
    id: 47,
    text: "He gets information about some terrorists, but his commander doesn't agree despite his repeated reminders. So he...",
    response:
      "Document the information, report it to higher authorities, and follow established protocols.",
  },
  {
    id: 48,
    text: "After running BPET, you noticed that you have lost the magazine of his rifle. So you...",
    response:
      "Immediately report the lost item, undergo necessary procedures, and ensure accountability.",
  },
  {
    id: 49,
    text: "Ganesh was an honest and faithful worker of his factory. He noticed the storekeeper selling the stores in the market. So Ganesh...",
    response:
      "Gather evidence, report the illegal activity to the appropriate authorities, and maintain confidentiality.",
  },
  {
    id: 50,
    text: "He forgot his identity card and proceeded on TD. He was denied access to the cantonment. He...",
    response:
      "Accept the consequences, rectify the situation by obtaining the required documentation, and seek necessary permissions.",
  },
  {
    id: 51,
    text: "The cook is not preparing tasty food. He is the Mess Cdr. So he...",
    response:
      "Evaluate the reasons for the substandard food and take corrective measures.",
  },
  {
    id: 52,
    text: "Ahmed's wife was seriously ill. His immediate superior refused him six days CL...",
    response:
      "Seek assistance from higher authorities or apply for leave through proper channels with appropriate documentation.",
  },
  {
    id: 53,
    text: "He missed his school bus at 0830h. The final exam was to start at 0900h. The school is at a distance of 3 Km. So he...",
    response:
      "Find an alternative mode of transportation or make arrangements to reach the school on time.",
  },
  {
    id: 54,
    text: "Ram is the monitor of his class. His friend plays truant. So Ram...",
    response:
      "Inform the teacher or relevant authority about the friend's absence and follow established procedures.",
  },
  {
    id: 55,
    text: "Being on guard duty, he noticed that Mohan ran amok and started firing toward a superior. So he...",
    response:
      "Take immediate action to neutralize the threat and protect the superior and others.",
  },
  {
    id: 56,
    text: "You went for a jog at 3 in the morning and found three masked men with guns walking on the road. You...",
    response:
      "Ensure personal safety, avoid confrontation, and report the situation to the appropriate authorities.",
  },
  {
    id: 57,
    text: "You have stopped at a red light when a child came and asked for some money. You...",
    response:
      "Exercise discretion, consider the child's well-being, and act in alignment with personal values and circumstances.",
  },
  {
    id: 58,
    text: "You are the CEO of a big organization, and many of your employees go on strike. You...",
    response:
      "Open channels of communication, address employees' concerns, and work towards a resolution.",
  },
  {
    id: 59,
    text: "You are heading a very secretive intel team and found one of the members passing necessary info to the enemy. You would...",
    response:
      "Collect evidence, report the betrayal to higher authorities, and take appropriate counterintelligence measures.",
  },
  {
    id: 60,
    text: "Few of your comrades have been held captive by the enemy. What will you do...",
    response:
      "Initiate rescue operations while considering the safety and feasibility of the mission.",
  },
];

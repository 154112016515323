import React from "react";
import "./notFoundPage.css";

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <h1>404 : Page Not Found.</h1>
    </div>
  );
};

export default NotFoundPage;

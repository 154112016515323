export const wordNine = [
  {
    id: 1,
    text: "Decision",
    response: "",
  },
  {
    id: 2,
    text: "Car",
    response: "",
  },
  {
    id: 3,
    text: "Heaven",
    response: "",
  },
  {
    id: 4,
    text: "Academic",
    response: "",
  },
  {
    id: 5,
    text: "Obvious",
    response: "",
  },
  {
    id: 6,
    text: "Taste",
    response: "",
  },
  {
    id: 7,
    text: "Web",
    response: "",
  },
  {
    id: 8,
    text: "Genuine",
    response: "",
  },
  {
    id: 9,
    text: "Fast",
    response: "",
  },
  {
    id: 10,
    text: "Cash",
    response: "",
  },
  {
    id: 11,
    text: "Manufacture",
    response: "",
  },
  {
    id: 12,
    text: "Headache",
    response: "",
  },
  {
    id: 13,
    text: "Accord",
    response: "",
  },
  {
    id: 14,
    text: "Feeling",
    response: "",
  },
  {
    id: 15,
    text: "Decorate",
    response: "",
  },
  {
    id: 16,
    text: "Bankrupt",
    response: "",
  },
  {
    id: 17,
    text: "Cattle",
    response: "",
  },
  {
    id: 18,
    text: "Right",
    response: "",
  },
  {
    id: 19,
    text: "Glad",
    response: "",
  },
  {
    id: 20,
    text: "Jibe",
    response: "",
  },
  {
    id: 21,
    text: "Native",
    response: "",
  },
  {
    id: 22,
    text: "Paranormal",
    response: "",
  },
  {
    id: 23,
    text: "Murder",
    response: "",
  },
  {
    id: 24,
    text: "Men",
    response: "",
  },
  {
    id: 25,
    text: "Test",
    response: "",
  },
  {
    id: 26,
    text: "Patient",
    response: "",
  },
  {
    id: 27,
    text: "Wife",
    response: "",
  },
  {
    id: 28,
    text: "Character",
    response: "",
  },
  {
    id: 29,
    text: "Pool",
    response: "",
  },
  {
    id: 30,
    text: "Navy",
    response: "",
  },
  {
    id: 31,
    text: "Juice",
    response: "",
  },
  {
    id: 32,
    text: "Kickoff",
    response: "",
  },
  {
    id: 33,
    text: "Lavatory",
    response: "",
  },
  {
    id: 34,
    text: "Nobody",
    response: "",
  },
  {
    id: 35,
    text: "October",
    response: "",
  },
  {
    id: 36,
    text: "West",
    response: "",
  },
  {
    id: 37,
    text: "Village",
    response: "",
  },
  {
    id: 38,
    text: "Park",
    response: "",
  },
  {
    id: 39,
    text: "Marriage",
    response: "",
  },
  {
    id: 40,
    text: "Best-friend",
    response: "",
  },
  {
    id: 41,
    text: "Imagine",
    response: "",
  },
  {
    id: 42,
    text: "Reader",
    response: "",
  },
  {
    id: 43,
    text: "Saving",
    response: "",
  },
  {
    id: 44,
    text: "Tea",
    response: "",
  },
  {
    id: 45,
    text: "Unknown",
    response: "",
  },
  {
    id: 46,
    text: "Whatever",
    response: "",
  },
  {
    id: 47,
    text: "Hesitate",
    response: "",
  },
  {
    id: 48,
    text: "Account",
    response: "",
  },
  {
    id: 49,
    text: "Effort",
    response: "",
  },
  {
    id: 50,
    text: "Felony",
    response: "",
  },
  {
    id: 51,
    text: "Mathematics",
    response: "",
  },
  {
    id: 52,
    text: "Reality",
    response: "",
  },
  {
    id: 53,
    text: "Technical",
    response: "",
  },
  {
    id: 54,
    text: "Immoral",
    response: "",
  },
  {
    id: 55,
    text: "Decrease",
    response: "",
  },
  {
    id: 56,
    text: "Lawyer",
    response: "",
  },
  {
    id: 57,
    text: "Realize",
    response: "",
  },
  {
    id: 58,
    text: "Elect",
    response: "",
  },
  {
    id: 59,
    text: "Bath",
    response: "",
  },
  {
    id: 60,
    text: "Fence",
    response: "",
  },
  {
    id: 60,
    text: "Fence",
    response: "",
  },
];

export const wordSeven = [
  {
    id: 1,
    text: "Youth",
    response: "",
  },
  {
    id: 2,
    text: "Habitat",
    response: "",
  },
  {
    id: 3,
    text: "Ability",
    response: "",
  },
  {
    id: 4,
    text: "Hate",
    response: "",
  },
  {
    id: 5,
    text: "Knowledge",
    response: "",
  },
  {
    id: 6,
    text: "Cage",
    response: "",
  },
  {
    id: 7,
    text: "Ice",
    response: "",
  },
  {
    id: 8,
    text: "Identical",
    response: "",
  },
  {
    id: 9,
    text: "Object",
    response: "",
  },
  {
    id: 10,
    text: "Jealous",
    response: "",
  },
  {
    id: 11,
    text: "Painter",
    response: "",
  },
  {
    id: 12,
    text: "Sacrifice",
    response: "",
  },
  {
    id: 13,
    text: "Damage",
    response: "",
  },
  {
    id: 14,
    text: "Kind",
    response: "",
  },
  {
    id: 15,
    text: "National",
    response: "",
  },
  {
    id: 16,
    text: "Qualification",
    response: "",
  },
  {
    id: 17,
    text: "Tactful",
    response: "",
  },
  {
    id: 18,
    text: "Doctor",
    response: "",
  },
  {
    id: 19,
    text: "Impartial",
    response: "",
  },
  {
    id: 20,
    text: "Tactless",
    response: "",
  },
  {
    id: 21,
    text: "Waive",
    response: "",
  },
  {
    id: 22,
    text: "Bachelor",
    response: "",
  },
  {
    id: 23,
    text: "Half",
    response: "",
  },
  {
    id: 24,
    text: "Racial",
    response: "",
  },
  {
    id: 25,
    text: "Ugly",
    response: "",
  },
  {
    id: 26,
    text: "Fair",
    response: "",
  },
  {
    id: 27,
    text: "Engine",
    response: "",
  },
  {
    id: 28,
    text: "Joint",
    response: "",
  },
  {
    id: 29,
    text: "Nausea",
    response: "",
  },
  {
    id: 30,
    text: "Negligence",
    response: "",
  },
  {
    id: 31,
    text: "Rapid",
    response: "",
  },
  {
    id: 32,
    text: "Unable",
    response: "",
  },
  {
    id: 33,
    text: "Music",
    response: "",
  },
  {
    id: 34,
    text: "Lesson",
    response: "",
  },
  {
    id: 35,
    text: "Century",
    response: "",
  },
  {
    id: 36,
    text: "Eager",
    response: "",
  },
  {
    id: 37,
    text: "Generation",
    response: "",
  },
  {
    id: 38,
    text: "Faith",
    response: "",
  },
  {
    id: 39,
    text: "Quarter",
    response: "",
  },
  {
    id: 40,
    text: "Joke",
    response: "",
  },
  {
    id: 41,
    text: "Journey",
    response: "",
  },
  {
    id: 42,
    text: "Walk",
    response: "",
  },
  {
    id: 43,
    text: "Back",
    response: "",
  },
  {
    id: 44,
    text: "Maintain",
    response: "",
  },
  {
    id: 45,
    text: "Fame",
    response: "",
  },
  {
    id: 46,
    text: "Painting",
    response: "",
  },
  {
    id: 47,
    text: "Sadness",
    response: "",
  },
  {
    id: 48,
    text: "Trick",
    response: "",
  },
  {
    id: 49,
    text: "Hardware",
    response: "",
  },
  {
    id: 50,
    text: "Objection",
    response: "",
  },
  {
    id: 51,
    text: "Tank",
    response: "",
  },
  {
    id: 52,
    text: "Dear",
    response: "",
  },
  {
    id: 53,
    text: "Early",
    response: "",
  },
  {
    id: 54,
    text: "Kingdom",
    response: "",
  },
  {
    id: 55,
    text: "Negative",
    response: "",
  },
  {
    id: 56,
    text: "Candidate",
    response: "",
  },
  {
    id: 57,
    text: "Warm",
    response: "",
  },
  {
    id: 58,
    text: "Rare",
    response: "",
  },
  {
    id: 59,
    text: "Laboratory",
    response: "",
  },
  {
    id: 60,
    text: "Nerve",
    response: "",
  },
  {
    id: 60,
    text: "Nerve",
    response: "",
  },
];

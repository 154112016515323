export const wordTen = [
  {
    id: 1,
    text: "Female",
    response: "",
  },
  {
    id: 2,
    text: "Glory",
    response: "",
  },
  {
    id: 3,
    text: "Offend",
    response: "",
  },
  {
    id: 4,
    text: "Scare",
    response: "",
  },
  {
    id: 5,
    text: "Movie",
    response: "",
  },
  {
    id: 6,
    text: "Ball",
    response: "",
  },
  {
    id: 7,
    text: "Violence",
    response: "",
  },
  {
    id: 8,
    text: "High",
    response: "",
  },
  {
    id: 9,
    text: "Battery",
    response: "",
  },
  {
    id: 10,
    text: "Battle",
    response: "",
  },
  {
    id: 11,
    text: "Festival",
    response: "",
  },
  {
    id: 12,
    text: "Abuse",
    response: "",
  },
  {
    id: 13,
    text: "Junior",
    response: "",
  },
  {
    id: 14,
    text: "Defeat",
    response: "",
  },
  {
    id: 15,
    text: "Offensive",
    response: "",
  },
  {
    id: 16,
    text: "Maximum",
    response: "",
  },
  {
    id: 17,
    text: "Noise",
    response: "",
  },
  {
    id: 18,
    text: "Tender",
    response: "",
  },
  {
    id: 19,
    text: "Scenery",
    response: "",
  },
  {
    id: 20,
    text: "Lead",
    response: "",
  },
  {
    id: 21,
    text: "Crush",
    response: "",
  },
  {
    id: 22,
    text: "Persecution",
    response: "",
  },
  {
    id: 23,
    text: "Mad",
    response: "",
  },
  {
    id: 24,
    text: "Difficult",
    response: "",
  },
  {
    id: 25,
    text: "Virus",
    response: "",
  },
  {
    id: 26,
    text: "Gun",
    response: "",
  },
  {
    id: 27,
    text: "Curiosity",
    response: "",
  },
  {
    id: 28,
    text: "Class",
    response: "",
  },
  {
    id: 29,
    text: "Tear",
    response: "",
  },
  {
    id: 30,
    text: "Grumpy",
    response: "",
  },
  {
    id: 31,
    text: "Worry",
    response: "",
  },
  {
    id: 32,
    text: "Delay",
    response: "",
  },
  {
    id: 33,
    text: "Least",
    response: "",
  },
  {
    id: 34,
    text: "Electric",
    response: "",
  },
  {
    id: 35,
    text: "Cave",
    response: "",
  },
  {
    id: 36,
    text: "High-Pressure",
    response: "",
  },
  {
    id: 37,
    text: "Accuse",
    response: "",
  },
  {
    id: 38,
    text: "Mayor",
    response: "",
  },
  {
    id: 39,
    text: "Open",
    response: "",
  },
  {
    id: 40,
    text: "Jury",
    response: "",
  },
  {
    id: 41,
    text: "Defend",
    response: "",
  },
  {
    id: 42,
    text: "Fever",
    response: "",
  },
  {
    id: 43,
    text: "Goal",
    response: "",
  },
  {
    id: 44,
    text: "Parliament",
    response: "",
  },
  {
    id: 45,
    text: "Participate",
    response: "",
  },
  {
    id: 46,
    text: "Score",
    response: "",
  },
  {
    id: 47,
    text: "Urge",
    response: "",
  },
  {
    id: 48,
    text: "Lecture",
    response: "",
  },
  {
    id: 49,
    text: "Impact",
    response: "",
  },
  {
    id: 50,
    text: "Meal",
    response: "",
  },
  {
    id: 51,
    text: "Win",
    response: "",
  },
  {
    id: 52,
    text: "Vomit",
    response: "",
  },
  {
    id: 53,
    text: "Notebook",
    response: "",
  },
  {
    id: 54,
    text: "Hill",
    response: "",
  },
  {
    id: 55,
    text: "Achieve",
    response: "",
  },
  {
    id: 56,
    text: "Change",
    response: "",
  },
  {
    id: 57,
    text: "Opposite",
    response: "",
  },
  {
    id: 58,
    text: "Sea",
    response: "",
  },
  {
    id: 59,
    text: "Field",
    response: "",
  },
  {
    id: 60,
    text: "Defense",
    response: "",
  },
  {
    id: 60,
    text: "Defense",
  },
];

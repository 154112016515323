import React from "react";
import Footers from "../footer/Footers";
import Navbar from "../navbar/navbar";
import "./legal.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="legal">
        <h1>Privacy Policy</h1>
        <p>
          {" "}
          Thank you for choosing to use our e-learning website
          (www.troppers.com). We value your privacy and are committed to
          protecting it through our compliance with this privacy policy
          (&quot;Policy&quot;).
        </p>

        <p>
          {" "}
          This Policy applies to information we collect when you use our Site,
          and describes how we collect, use, and share information about you. By
          using our Site, you accept the collection, use, and sharing of your
          information as described in this Policy. If you do not agree with our
          policies and practices, do not use our Site.
        </p>

        <p>
          We may change our policies and practices from time to time, and we
          encourage you to review this Policy periodically. We will post any
          changes on this page and, if the changes are significant, we will
          provide a more prominent notice (including, for certain services,
          email notification of privacy policy changes). We encourage you to
          review the privacy policy whenever you access our services to stay
          informed about our information practices and the choices available to
          you.
        </p>

        <p className="legal-point">Information We Collect:-</p>

        <p>
          We collect information about you in various ways when you use our
          Site.
        </p>

        <p>
          Information You Provide to Us: We collect information you provide
          directly to us, such as when you create an account, place an order, or
          sign up for emails. This information may include your name, email
          address, phone number, and payment information.
        </p>

        <p>
          Cookies and Other Tracking Technologies: We use cookies and similar
          tracking technologies (e.g., web beacons, pixels, and mobile device
          IDs) to collect information about you when you use our Site. Cookies
          are small text files that are placed on your device to store data that
          can be accessed by a web server. We use both session cookies (which
          expire after you close your web browser) and persistent cookies (which
          stay on your device until you delete them). We use cookies and other
          tracking technologies for a variety of purposes, such as to remember
          your preferences and to track your use of our Site.
        </p>

        <p className="legal-point">How We Use Your Information:-</p>

        <p>We use information about you for various purposes, such as:</p>

        <p>
          1.To provide and improve our Site: We use your information to provide
          and improve our Site and services, including to process orders,
          authenticate users, and personalize content.
        </p>

        <p>
          2. To communicate with you: We use your information to communicate
          with you, such as to send you emails or push notifications about your
          account or order.
        </p>

        <p>
          3. To protect our rights and interests: We may use your information to
          protect our rights and interests, such as to enforce our policies or
          to prevent fraud or abuse.
        </p>

        <p className="legal-point"> Sharing Your Information</p>

        <p>
          We may share your information with third parties in the following
          ways:
        </p>

        <p>
          1. With service providers: We may share your information with
          third-party service providers that perform services on our behalf,
          such as payment processing, data analysis, and email delivery.
        </p>

        <p>
          2. With business partners: We may share your information with our
          business partners, such as co-branded partners or sponsors.
        </p>

        <p>
          3. For legal reasons: We may share your information if we believe it
          is necessary to comply with a legal obligation, such as to respond to
          a subpoena or to protect the rights, property, or safety of our
          company or others.
        </p>

        <p>
          4. In the event of a change in ownership: If we sell or transfer all
          or a portion of our business, or if we merge with another company, we
          may share your information with the new owner or merger partner.
        </p>

        <p>Your Choices</p>

        <p>You have the following</p>

        <p></p>
      </div>
      <Footers />
    </>
  );
};

export default PrivacyPolicy;

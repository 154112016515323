export const wordSix = [
  {
    id: 1,
    text: "Delight ",
    response: "",
  },
  {
    id: 2,
    text: "Empire",
    response: "",
  },
  {
    id: 3,
    text: "Employment",
    response: "",
  },
  {
    id: 4,
    text: "Harvest",
    response: "",
  },
  {
    id: 5,
    text: "Ego",
    response: "",
  },
  {
    id: 6,
    text: "Allowance",
    response: "",
  },
  {
    id: 7,
    text: "Loneliness",
    response: "",
  },
  {
    id: 8,
    text: "Nutrition",
    response: "",
  },
  {
    id: 9,
    text: "Offend",
    response: "",
  },
  {
    id: 10,
    text: "Unemployment",
    response: "",
  },
  {
    id: 11,
    text: "Winner",
    response: "",
  },
  {
    id: 12,
    text: "Flame",
    response: "",
  },
  {
    id: 13,
    text: "Flavor",
    response: "",
  },
  {
    id: 14,
    text: "Kid",
    response: "",
  },
  {
    id: 15,
    text: "Cheerful",
    response: "",
  },
  {
    id: 16,
    text: "Chemical",
    response: "",
  },
  {
    id: 17,
    text: "Ambition",
    response: "",
  },
  {
    id: 18,
    text: "Flaw",
    response: "",
  },
  {
    id: 19,
    text: "Partner",
    response: "",
  },
  {
    id: 20,
    text: "Path",
    response: "",
  },
  {
    id: 21,
    text: "Vision ",
    response: "",
  },
  {
    id: 22,
    text: "Demonstrate",
    response: "",
  },
  {
    id: 23,
    text: "Scientific",
    response: "",
  },
  {
    id: 24,
    text: "Thesis",
    response: "",
  },
  {
    id: 25,
    text: "Christmas",
    response: "",
  },
  {
    id: 26,
    text: "Oil",
    response: "",
  },
  {
    id: 27,
    text: "Pattern",
    response: "",
  },
  {
    id: 28,
    text: "Union",
    response: "",
  },
  {
    id: 29,
    text: "Grief",
    response: "",
  },
  {
    id: 30,
    text: "Old",
    response: "",
  },
  {
    id: 31,
    text: "Groundwater",
    response: "",
  },
  {
    id: 32,
    text: "Flood",
    response: "",
  },
  {
    id: 33,
    text: "Magic",
    response: "",
  },
  {
    id: 34,
    text: "Hang",
    response: "",
  },
  {
    id: 35,
    text: "Analyze",
    response: "",
  },
  {
    id: 36,
    text: "Below",
    response: "",
  },
  {
    id: 37,
    text: "Lord",
    response: "",
  },
  {
    id: 38,
    text: "Penalty",
    response: "",
  },
  {
    id: 39,
    text: "Performance",
    response: "",
  },
  {
    id: 40,
    text: "Winter",
    response: "",
  },
  {
    id: 41,
    text: "Flower",
    response: "",
  },
  {
    id: 42,
    text: "Bible",
    response: "",
  },
  {
    id: 43,
    text: "Church",
    response: "",
  },
  {
    id: 44,
    text: "Nuclear",
    response: "",
  },
  {
    id: 45,
    text: "Flu",
    response: "",
  },
  {
    id: 46,
    text: "Oppress",
    response: "",
  },
  {
    id: 47,
    text: "Indicate",
    response: "",
  },
  {
    id: 48,
    text: "Contradict",
    response: "",
  },
  {
    id: 49,
    text: "Anguish",
    response: "",
  },
  {
    id: 50,
    text: "Biodiversity",
    response: "",
  },
  {
    id: 51,
    text: "Mediation",
    response: "",
  },
  {
    id: 52,
    text: "Optimistic",
    response: "",
  },
  {
    id: 53,
    text: "Threaten",
    response: "",
  },
  {
    id: 54,
    text: "Witty",
    response: "",
  },
  {
    id: 55,
    text: "Period",
    response: "",
  },
  {
    id: 56,
    text: "Melt ",
    response: "",
  },
  {
    id: 57,
    text: "Envy",
    response: "",
  },
  {
    id: 58,
    text: "Entertain",
    response: "",
  },
  {
    id: 59,
    text: "Witness ",
    response: "",
  },
  {
    id: 60,
    text: "Thunder",
    response: "",
  },
  {
    id: 60,
    text: "Thunder",
    response: "",
  },
];

export const situationsEight = [
  {
    id: 1,
    text: "You come to know that your neighbour is supplying oxygen cylinder illegally on a very high price during pandemic. What would you do...",
    response:
      "Report the illegal activity to the authorities or a helpline dedicated to pandemic-related issues.",
  },
  {
    id: 2,
    text: "She would like to attend the late evening classes because of the specific unavailability of a physics teacher. Her parents have refused, she does not have any alternate except to attend. She...",
    response:
      "Discuss the importance of the class with her parents, explore alternative solutions like private tutoring or online resources.",
  },
  {
    id: 3,
    text: "On the picnic, his friends started playing cards in the park, but he doesn’t know the game. He...",
    response:
      "Ask his friends to teach him the game or engage in other activities during the card game.",
  },
  {
    id: 4,
    text: "He found the referee giving a goal to the opposite team partially. His teammates started shouting at the referee. He...",
    response:
      "Stay calm, encourage his teammates to maintain sportsmanship, and report the incident to the relevant sports authorities if necessary.",
  },
  {
    id: 5,
    text: "He and his friends decide to go to Shimla on a trip by car. They booked the car in advance. On the day of departure, the driver refused to go. He...",
    response:
      "Contact the car rental company to resolve the issue, explore alternative transportation options, or consider rescheduling the trip.",
  },
  {
    id: 6,
    text: "He withdrew Rs 50000/- from his bank but forgot the bundle of money on the counter. On his way home, he was reminded about the money. He...",
    response:
      "Immediately return to the bank and inform the staff about the forgotten money.",
  },
  {
    id: 7,
    text: "He entered the train to find a man sprawled motionless on the floor of the compartment. He...",
    response:
      "Alert the train staff or conductor about the situation and seek medical help for the motionless man.",
  },
  {
    id: 8,
    text: "His friend had borrowed a thousand from him. Now, his friend again wanted another thousand. He...",
    response:
      "Consider the circumstances and his friend's repayment ability before deciding whether to lend more money.",
  },
  {
    id: 9,
    text: "Three months before his final examination, he failed in the pre-board mathematics paper. He...",
    response:
      "Identify areas of weakness, seek additional help like tutoring or study groups, and focus on improving for the final examination.",
  },
  {
    id: 10,
    text: "He wanted to purchase a new pair of jeans. He had saved his pocket money but found that he ran short by a hundred. He...",
    response:
      "Consider alternative options like saving more money or exploring discounts/offers to purchase the desired pair of jeans.",
  },
  {
    id: 11,
    text: "He was traveling on a reserved seat in the bus. Two hooligans came and asked him at knife point to vacate the seat. He...",
    response:
      "Prioritize personal safety, avoid confrontation, and inform the bus staff or authorities about the situation.",
  },
  {
    id: 12,
    text: "He saw two persons fighting with each other. One of them got serious injuries. He...",
    response:
      "Call emergency services or the police to report the fight and provide necessary information about the injured person.",
  },
  {
    id: 13,
    text: "He was traveling in a train. Two miscreants started molesting a girl. He...",
    response:
      "Intervene to stop the harassment, seek help from other passengers or train staff, and ensure the safety of the girl.",
  },
  {
    id: 14,
    text: "You are passing through a dense forest on a bike and suddenly hear the scream of a girl. You...",
    response:
      "Investigate the situation cautiously, assess the level of danger, and inform the authorities or nearby help if necessary.",
  },
  {
    id: 15,
    text: "On his way, he saw a man suddenly falling down on the road. On reaching him, he comes to know that he is a heart patient. He...",
    response:
      "Check the man's condition, provide immediate assistance like calling for medical help or administering CPR if trained to do so.",
  },
  {
    id: 16,
    text: "You are a reporter reporting from a War outbreak area, and the soldier instructing you gets injured due to heavy shelling...",
    response:
      "Ensure personal safety, seek medical assistance for the injured soldier, and report the incident to the relevant authorities.",
  },
  {
    id: 17,
    text: "One day in the absence of her father, her stepmother abused her and assaulted her badly and even threatened to dislodge her from the house. She...",
    response:
      "Reach out to a trusted adult, relative, or helpline to report the abuse and seek assistance.",
  },
  {
    id: 18,
    text: "He reached the examination hall 30 minutes late due to a traffic problem. The invigilator refused to give him entry in the hall. He...",
    response:
      "Request the invigilator for leniency or explain the circumstances, and if not allowed, consult the exam authorities for possible solutions.",
  },
  {
    id: 19,
    text: "He wants to join the army but failed in the NDA exam in his last attempt. He...",
    response:
      "Explore alternative paths or exams to pursue a career in the armed forces, seek guidance from mentors, or consider other career options.",
  },
  {
    id: 20,
    text: "Your neighbor has gone on vacation, and you hear a loud sound coming from their house at midnight. You...",
    response:
      "Investigate cautiously, ensuring personal safety, and if necessary, inform the authorities or seek help from neighbors.",
  },
  {
    id: 21,
    text: "His father was forcing him to continue his studies with science and maths, but his interest was in literature. He...",
    response:
      "Communicate his passion for literature to his father, discuss career options in the field, and find a middle ground for his studies.",
  },
  {
    id: 22,
    text: "His parents were out for a week. All of a sudden, his younger brother developed a high fever and became unconscious. He...",
    response:
      "Contact emergency medical services, provide necessary first aid, and inform his parents about the situation.",
  },
  {
    id: 23,
    text: "While negotiating a forest with his friend, his friend lost consciousness due to exhaustion. He...",
    response:
      "Provide immediate assistance like offering water, finding a shaded area, and calling for medical help if necessary.",
  },
  {
    id: 24,
    text: "He received an SSB interview call-up letter when his parents were away on a tour. He had no money. He...",
    response:
      "Reach out to a trusted friend or relative for financial support or explore options for travel assistance from the interview authorities.",
  },
  {
    id: 25,
    text: "His uncle had a quarrel with his father and threatened to kill him. He...",
    response:
      "Ensure personal safety and inform the police or other relevant authorities about the threat.",
  },
  {
    id: 26,
    text: "He was taking his ailing brother to a hospital but was stopped by the police for overspeeding. He...",
    response:
      "Explain the situation to the police, provide necessary documents like medical records, and request their assistance in reaching the hospital quickly.",
  },
  {
    id: 27,
    text: "His mother wanted him to take her to her daughter’s house on the eve of a festival. But he had other commitments. He...",
    response:
      "Communicate his prior commitments to his mother, explore alternative ways for her to reach her daughter's house, or find a compromise.",
  },
  {
    id: 28,
    text: "He realized that his parents had been pressurizing his wife for getting a lot of money from her parents. He...",
    response:
      "Have a conversation with his parents and wife, addressing the issue and finding a resolution that respects everyone's feelings and boundaries.",
  },
  {
    id: 29,
    text: "His mother wanted him to attend the last rites of one of his close relations. But his wife had already arranged for cinema tickets. He...",
    response:
      "Prioritize attending the last rites, explain the situation to his wife, and explore options for rescheduling or refunding the cinema tickets.",
  },
  {
    id: 30,
    text: "He arranged a loan for his friend on his personal guarantee. After paying two installments, the latter stopped payment, and the bank asked him to pay. He...",
    response:
      "Consult a lawyer or seek legal advice regarding the loan agreement, negotiate with the friend to resume payments, or explore options to resolve the situation legally.",
  },
  {
    id: 31,
    text: "A young teacher of her college makes frequent visits to her house which she does not like. She would...",
    response:
      "Politely communicate her discomfort and set boundaries with the teacher.",
  },
  {
    id: 32,
    text: "You got information that the enemy is likely to attack one of your posts. As a serving soldier, what do you do...",
    response:
      "Alert your superiors, reinforce the post's defenses, and prepare for a potential attack.",
  },
  {
    id: 33,
    text: "You are at a hotel, and a girl comes at midnight and wants your financial help. You will...",
    response:
      "Assess the situation and offer assistance within your means or direct her to appropriate resources for help.",
  },
  {
    id: 34,
    text: "He is on leave, his wife commits a severe crime, and his leave is about to get over. He...",
    response:
      "Report the situation to the authorities, fulfill his duty obligations, and let the legal process take its course.",
  },
  {
    id: 35,
    text: "Two groups are quarreling over a religious problem, and he belongs to the minority group. He will...",
    response:
      "Promote dialogue, understanding, and peaceful resolution between the groups.",
  },
  {
    id: 36,
    text: "He is working in an organization, and one of his close friends, in the same office, is being harassed by his boss. He will...",
    response:
      "Support and stand up for his friend, document incidents, and report the harassment to higher authorities.",
  },
  {
    id: 37,
    text: "During her examination, her parents have been quarreling, and even her brother is not supporting her. She...",
    response:
      "Find a quiet and supportive environment, seek external help or guidance, and focus on her studies.",
  },
  {
    id: 38,
    text: "His marriage has been fixed, but one week prior to the marriage, the girl meets with an accident and loses one eye. He will...",
    response:
      "Reassess his commitment to the relationship, prioritize empathy and support for the girl, and make an informed decision.",
  },
  {
    id: 39,
    text: "He lent some money to his friend. Now he needs this money badly, but the friend is not in a position to pay. He will...",
    response:
      "Have an open and honest conversation with his friend, exploring options for repayment or finding a mutually agreeable solution.",
  },
  {
    id: 40,
    text: "His parents have asked him to return early in the evening, but his friends want to celebrate a late evening party. He will...",
    response:
      "Communicate with his parents about the situation, find a compromise, or prioritize family obligations.",
  },
  {
    id: 41,
    text: "His brother is leaving for the USA after one week, but he is to report for duty in an army unit deployed on the border area. He...",
    response:
      "Discuss the situation with his brother and superiors, exploring possibilities for rescheduling or adjusting the reporting date.",
  },
  {
    id: 42,
    text: "He is the manager of a factory, facing a serious labor problem. His sister's marriage is after one week, and he cannot leave the station. He...",
    response:
      "Seek assistance from higher management, delegate responsibilities, or explore options for resolving the labor problem remotely.",
  },
  {
    id: 43,
    text: "He is fighting college elections, but all the girls are with his opponent, who is utilizing them to snatch the vote. He will...",
    response:
      "Focus on his campaign strategy, engage with students, present his vision and plans, and seek their support based on merit.",
  },
  {
    id: 44,
    text: "At midnight, he hears a female voice, close to his house, apparently in a terrified manner. He will...",
    response:
      "Investigate the situation cautiously, call for help if needed, and ensure the safety and well-being of the person in distress.",
  },
  {
    id: 45,
    text: "The duty officer informed him at odd hours that his CO wanted him in his office immediately. He...",
    response:
      "Promptly report to his CO's office, prepared to fulfill any assigned tasks or responsibilities.",
  },
  {
    id: 46,
    text: "He returned late to his house and found his father and mother quarreling. He...",
    response:
      "Intervene calmly, listen to both sides, and mediate to resolve the conflict between his parents.",
  },
  {
    id: 47,
    text: "His elder brother used to ignore his family commitments and instead indulged in heavy drinking. He...",
    response:
      "Express concern for his brother's well-being, encourage open communication, and explore avenues for support and rehabilitation.",
  },
  {
    id: 48,
    text: "While returning from evening coaching classes, a group of boys are passing comments on her. She would...",
    response:
      "Ignore the comments, seek safety in a public place, or report the incident to the appropriate authorities.",
  },
  {
    id: 49,
    text: "He was running a small restaurant. He was informed by his staff that they had seen a mouse falling in the daal being cooked there. He...",
    response:
      "Take immediate action to address the issue, ensure hygiene and safety standards are maintained, and communicate with customers transparently.",
  },
  {
    id: 50,
    text: "In his area, the crime rate had gone up. Being the police in charge of that area, he was asked to normalize the situation within one month. He...",
    response:
      "Strengthen patrolling and surveillance, collaborate with the community, and implement proactive crime prevention measures.",
  },
  {
    id: 51,
    text: "At the railway station, he found a beggar shivering with cold. He is going to meet his relative and is wearing pants and a pullover only. He would...",
    response:
      "Offer warm clothing or blankets to the beggar, provide any available assistance, and proceed with his travel arrangements.",
  },
  {
    id: 52,
    text: "After Shikar, he with his friends reached the circuit house to spend the night but found that the Chowkidar was absent. He would...",
    response:
      "Ensure the security of the circuit house, notify the appropriate authorities about the absence, and make necessary arrangements for safety.",
  },
  {
    id: 53,
    text: "While preparing for her civil services examination, she gets into a love affair with a boy who insists that they get married immediately despite her preparation for exams. She...",
    response:
      "Prioritize her studies and career goals, have an open and honest conversation with the boy about her priorities, and make a decision based on her long-term aspirations.",
  },
  {
    id: 54,
    text: "He is returning from college on a scooter, an old man asks for a lift, but his rear wheel has very little air. He...",
    response:
      "Offer the old man a safe ride, inform him about the low air pressure, and assist him in finding a suitable alternative if needed.",
  },
  {
    id: 55,
    text: "You went to the cinema with your friends, but the house is full. What do you do...",
    response:
      "Explore alternative entertainment options, reschedule the movie outing, or consider watching a movie at a different theater.",
  },
  {
    id: 56,
    text: "His brother has decided to marry a girl whose nature is not good. So he...",
    response:
      "Express concern and discuss his reservations with his brother, encouraging open communication and considering the long-term implications.",
  },
  {
    id: 57,
    text: "Generally, his neighbor, after having liquor, comes late at night and starts disturbing him and his parents. He...",
    response:
      "Seek dialogue and understanding with the neighbor, involve local authorities if necessary, and ensure the safety and peace of his family.",
  },
  {
    id: 58,
    text: "You are a serving officer in the Indian Army, and your mother is serious and wants to meet you, but the war has been declared. You...",
    response:
      "Communicate with superiors about the situation, explore possibilities for compassionate leave or alternative arrangements to attend to his mother.",
  },
  {
    id: 59,
    text: "When her father was out of station, her stepmother abused her and finally dislodged her from the house and took all the money she had. She...",
    response:
      "Seek immediate help or protection from relevant authorities, reach out to trusted individuals, and explore legal recourse for her rights and safety.",
  },
  {
    id: 60,
    text: "You have been detailed to go with your CO on temp duty, but your younger brother got severely injured. You...",
    response:
      "Inform your CO about the situation, express concern for your brother's well-being, and explore options for assistance or support.",
  },
];

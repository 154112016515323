export const wordEight = [
  {
    id: 1,
    text: "Leadership",
    response: "",
  },
  {
    id: 2,
    text: "Nudity",
    response: "",
  },
  {
    id: 3,
    text: "Fame",
    response: "",
  },
  {
    id: 4,
    text: "Murder",
    response: "",
  },
  {
    id: 5,
    text: "Decision-making",
    response: "",
  },
  {
    id: 6,
    text: "Adaptability",
    response: "",
  },
  {
    id: 7,
    text: "Tragic",
    response: "",
  },
  {
    id: 8,
    text: "Attitude",
    response: "",
  },
  {
    id: 9,
    text: "Teamwork",
    response: "",
  },
  {
    id: 10,
    text: "Dedicate",
    response: "",
  },
  {
    id: 11,
    text: "Communication",
    response: "",
  },
  {
    id: 12,
    text: "Vegetable",
    response: "",
  },
  {
    id: 13,
    text: "Problem-solving",
    response: "",
  },
  {
    id: 14,
    text: "Resilience",
    response: "",
  },
  {
    id: 15,
    text: "Ecology",
    response: "",
  },
  {
    id: 16,
    text: "Therapist",
    response: "",
  },
  {
    id: 17,
    text: "Self-centered",
    response: "",
  },
  {
    id: 18,
    text: "Greenhouse",
    response: "",
  },
  {
    id: 19,
    text: "Hardware",
    response: "",
  },
  {
    id: 20,
    text: "Deal",
    response: "",
  },
  {
    id: 21,
    text: "Emotional",
    response: "",
  },
  {
    id: 22,
    text: "Universe",
    response: "",
  },
  {
    id: 23,
    text: "Wisdom",
    response: "",
  },
  {
    id: 24,
    text: "Worker",
    response: "",
  },
  {
    id: 25,
    text: "Wreck",
    response: "",
  },
  {
    id: 26,
    text: "Intelligence",
    response: "",
  },
  {
    id: 27,
    text: "Phobia",
    response: "",
  },
  {
    id: 28,
    text: "Construction",
    response: "",
  },
  {
    id: 29,
    text: "Bengal",
    response: "",
  },
  {
    id: 30,
    text: "Creativity",
    response: "",
  },
  {
    id: 31,
    text: "Empathy",
    response: "",
  },
  {
    id: 32,
    text: "Top",
    response: "",
  },
  {
    id: 33,
    text: "Robust",
    response: "",
  },
  {
    id: 34,
    text: "Prominent",
    response: "",
  },
  {
    id: 35,
    text: "Paradise",
    response: "",
  },
  {
    id: 36,
    text: "Motivation",
    response: "",
  },
  {
    id: 37,
    text: "Sanskrit",
    response: "",
  },
  {
    id: 38,
    text: "Transsexuals",
    response: "",
  },
  {
    id: 39,
    text: "Tumour",
    response: "",
  },
  {
    id: 40,
    text: "Urdu",
    response: "",
  },
  {
    id: 41,
    text: "Buddha",
    response: "",
  },
  {
    id: 42,
    text: "Elimination",
    response: "",
  },
  {
    id: 43,
    text: "Diverse",
    response: "",
  },
  {
    id: 44,
    text: "Smartphone",
    response: "",
  },
  {
    id: 45,
    text: "Good",
    response: "",
  },
  {
    id: 46,
    text: "Orientation",
    response: "",
  },
  {
    id: 47,
    text: "Supreme",
    response: "",
  },
  {
    id: 48,
    text: "Conflict",
    response: "",
  },
  {
    id: 49,
    text: "Superstar",
    response: "",
  },
  {
    id: 50,
    text: "Unseen",
    response: "",
  },
  {
    id: 51,
    text: "Setback",
    response: "",
  },
  {
    id: 52,
    text: "Bad-luck",
    response: "",
  },
  {
    id: 53,
    text: "Sorrow",
    response: "",
  },
  {
    id: 54,
    text: "Mist",
    response: "",
  },
  {
    id: 55,
    text: "Tamil",
    response: "",
  },
  {
    id: 56,
    text: "Sore",
    response: "",
  },
  {
    id: 57,
    text: "Credible",
    response: "",
  },
  {
    id: 58,
    text: "Launder",
    response: "",
  },
  {
    id: 59,
    text: "Initiative",
    response: "",
  },
  {
    id: 60,
    text: "Bad",
    response: "",
  },
];

export const wordTen = [
  {
    id: 1,
    text: "Merry",
    response: "",
  },
  {
    id: 2,
    text: "Jubilant",
    response: "",
  },
  {
    id: 3,
    text: "Silly",
    response: "",
  },
  {
    id: 4,
    text: "Avid",
    response: "",
  },
  {
    id: 5,
    text: "Discouraged",
    response: "",
  },
  {
    id: 6,
    text: "Mastery",
    response: "",
  },
  {
    id: 7,
    text: "Yourself",
    response: "",
  },
  {
    id: 8,
    text: "Bossy",
    response: "",
  },
  {
    id: 9,
    text: "Fussy",
    response: "",
  },
  {
    id: 10,
    text: "Witty",
    response: "",
  },
  {
    id: 11,
    text: "Hitler",
    response: "",
  },
  {
    id: 12,
    text: "Homeless",
    response: "",
  },
  {
    id: 13,
    text: "Kashmir",
    response: "",
  },
  {
    id: 14,
    text: "IAS",
    response: "",
  },
  {
    id: 15,
    text: "Russia",
    response: "",
  },
  {
    id: 16,
    text: "Apple",
    response: "",
  },
  {
    id: 17,
    text: "Nutritionist",
    response: "",
  },
  {
    id: 18,
    text: "Remarkable",
    response: "",
  },
  {
    id: 19,
    text: "Milk",
    response: "",
  },
  {
    id: 20,
    text: "Ace",
    response: "",
  },
  {
    id: 21,
    text: "Bounty",
    response: "",
  },
  {
    id: 22,
    text: "Dazzling",
    response: "",
  },
  {
    id: 23,
    text: "Decay",
    response: "",
  },
  {
    id: 24,
    text: "Can't",
    response: "",
  },
  {
    id: 25,
    text: "Filthy",
    response: "",
  },
  {
    id: 26,
    text: "Ethical",
    response: "",
  },
  {
    id: 27,
    text: "Fresh",
    response: "",
  },
  {
    id: 28,
    text: "Gruesome",
    response: "",
  },
  {
    id: 29,
    text: "Insidious",
    response: "",
  },
  {
    id: 30,
    text: "ill",
    response: "",
  },
  {
    id: 31,
    text: "Quota",
    response: "",
  },
  {
    id: 32,
    text: "UNSC",
    response: "",
  },
  {
    id: 33,
    text: "Counselling",
    response: "",
  },
  {
    id: 34,
    text: "Former",
    response: "",
  },
  {
    id: 35,
    text: "Narrative",
    response: "",
  },
  {
    id: 36,
    text: "Rajasthan",
    response: "",
  },
  {
    id: 37,
    text: "Freak",
    response: "",
  },
  {
    id: 38,
    text: "Conservation",
    response: "",
  },
  {
    id: 39,
    text: "Mind-blowing",
    response: "",
  },
  {
    id: 40,
    text: "Foundation",
    response: "",
  },
  {
    id: 41,
    text: "Plastic",
    response: "",
  },
  {
    id: 42,
    text: "Commentator",
    response: "",
  },
  {
    id: 43,
    text: "Mafia",
    response: "",
  },
  {
    id: 44,
    text: "Comedian",
    response: "",
  },
  {
    id: 45,
    text: "Iteration",
    response: "",
  },
  {
    id: 46,
    text: "Fool",
    response: "",
  },
  {
    id: 47,
    text: "Speedy",
    response: "",
  },
  {
    id: 48,
    text: "Chill",
    response: "",
  },
  {
    id: 49,
    text: "Moments",
    response: "",
  },
  {
    id: 50,
    text: "Articulate",
    response: "",
  },
  {
    id: 51,
    text: "Unreliable",
    response: "",
  },
  {
    id: 52,
    text: "Dynamic",
    response: "",
  },
  {
    id: 53,
    text: "Acute",
    response: "",
  },
  {
    id: 54,
    text: "Extraversion",
    response: "",
  },
  {
    id: 55,
    text: "Nowhere",
    response: "",
  },
  {
    id: 56,
    text: "Attain",
    response: "",
  },
  {
    id: 57,
    text: "Darling",
    response: "",
  },
  {
    id: 58,
    text: "Far-sighted",
    response: "",
  },
  {
    id: 59,
    text: "Eye-catching",
    response: "",
  },
  {
    id: 60,
    text: "Ample",
    response: "",
  },
];

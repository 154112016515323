export const wordEight = [
  {
    id: 1,
    text: "Capacity",
    response: "",
  },
  {
    id: 2,
    text: "Identification",
    response: "",
  },
  {
    id: 3,
    text: "Observe",
    response: "",
  },
  {
    id: 4,
    text: "Kneel",
    response: "",
  },
  {
    id: 5,
    text: "Wife",
    response: "",
  },
  {
    id: 6,
    text: "Waste",
    response: "",
  },
  {
    id: 7,
    text: "Safe",
    response: "",
  },
  {
    id: 8,
    text: "Headache",
    response: "",
  },
  {
    id: 9,
    text: "Generosity",
    response: "",
  },
  {
    id: 10,
    text: "Familiar",
    response: "",
  },
  {
    id: 11,
    text: "guard",
    response: "",
  },
  {
    id: 12,
    text: "Valley",
    response: "",
  },
  {
    id: 13,
    text: "Undertake",
    response: "",
  },
  {
    id: 14,
    text: "Suicide",
    response: "",
  },
  {
    id: 15,
    text: "Heredity",
    response: "",
  },
  {
    id: 16,
    text: "Food",
    response: "",
  },
  {
    id: 17,
    text: "Alert",
    response: "",
  },
  {
    id: 18,
    text: "Positive",
    response: "",
  },
  {
    id: 19,
    text: "Nervous",
    response: "",
  },
  {
    id: 20,
    text: "Suspend",
    response: "",
  },
  {
    id: 21,
    text: "Undo",
    response: "",
  },
  {
    id: 22,
    text: "Salad",
    response: "",
  },
  {
    id: 23,
    text: "Salary",
    response: "",
  },
  {
    id: 24,
    text: "Rash",
    response: "",
  },
  {
    id: 25,
    text: "Famous",
    response: "",
  },
  {
    id: 26,
    text: "Judgement",
    response: "",
  },
  {
    id: 27,
    text: "Nest",
    response: "",
  },
  {
    id: 28,
    text: "Landmine",
    response: "",
  },
  {
    id: 29,
    text: "Question",
    response: "",
  },
  {
    id: 30,
    text: "Target",
    response: "",
  },
  {
    id: 31,
    text: "Salt",
    response: "",
  },
  {
    id: 32,
    text: "Laugh",
    response: "",
  },
  {
    id: 33,
    text: "Majority",
    response: "",
  },
  {
    id: 34,
    text: "Backward",
    response: "",
  },
  {
    id: 35,
    text: "Abusive",
    response: "",
  },
  {
    id: 36,
    text: "Heal",
    response: "",
  },
  {
    id: 37,
    text: "Malice",
    response: "",
  },
  {
    id: 38,
    text: "Obsessive",
    response: "",
  },
  {
    id: 39,
    text: "Knife",
    response: "",
  },
  {
    id: 40,
    text: "Illness",
    response: "",
  },
  {
    id: 41,
    text: "Capture",
    response: "",
  },
  {
    id: 42,
    text: "capital",
    response: "",
  },
  {
    id: 43,
    text: "Ease",
    response: "",
  },
  {
    id: 44,
    text: "Gentle",
    response: "",
  },
  {
    id: 45,
    text: "Manager",
    response: "",
  },
  {
    id: 46,
    text: "Obtain",
    response: "",
  },
  {
    id: 47,
    text: "Unfortunate",
    response: "",
  },
  {
    id: 48,
    text: "Quote",
    response: "",
  },
  {
    id: 49,
    text: "Knock",
    response: "",
  },
  {
    id: 50,
    text: "Fan",
    response: "",
  },
  {
    id: 51,
    text: "Badly",
    response: "",
  },
  {
    id: 52,
    text: "Sample",
    response: "",
  },
  {
    id: 53,
    text: "Disgust",
    response: "",
  },
  {
    id: 54,
    text: "Edict",
    response: "",
  },
  {
    id: 55,
    text: "Easygoing",
    response: "",
  },
  {
    id: 56,
    text: "Image",
    response: "",
  },
  {
    id: 57,
    text: "Dearth",
    response: "",
  },
  {
    id: 58,
    text: "Sarcastic",
    response: "",
  },
  {
    id: 59,
    text: "Far",
    response: "",
  },
  {
    id: 60,
    text: "Decay",
    response: "",
  },
  {
    id: 60,
    text: "Decay",
    response: "",
  },
];
